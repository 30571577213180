import dayjs from 'dayjs';
import Helper from '@/modules/helper';
import I18n from '@/modules/i18n';

export default class HandlebarsHelpers {
  static init() {
    Handlebars.registerHelper({
      and: function (v1, v2) {
        return v1 && v2;
      },

      eq: (v1, v2) => {
        return v1 === v2;
      },

      gt: (v1, v2) => {
        return v1 > v2;
      },

      gte: (v1, v2) => {
        return v1 >= v2;
      },

      i18n: (path) => {
        return I18n.t(path);
      },

      i18n_capitalized: (path) => {
        const string = I18n.t(path);

        return string.charAt(0).toUpperCase() + string.slice(1);
      },

      lt: (v1, v2) => {
        return v1 < v2;
      },

      lte: (v1, v2) => {
        return v1 <= v2;
      },

      ne: (v1, v2) => {
        return v1 !== v2;
      },

      or: (v1, v2) => {
        return v1 || v2;
      },

      csrf: () => {
        return $('meta[name="csrf-token"]').attr('content');
      },

      log: (message) => {
        console.log(message);
      },

      now: (mask) => {
        return dayjs().format(mask || Helper.dateFormat());
      },

      size: (collection) => {
        if (collection && collection.length) {
          return collection.length;
        }

        return 0;
      },
    });
  }
}
