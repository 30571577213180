import modulejs from 'modulejs';

import GridyCommon from '@/modules/gridy_common';
import I18n from '@/modules/i18n';
import MenuActions from '@/modules/menu_actions';
import Tip from '@/modules/tip';

const Templates = require('@/pages/site/templates');

modulejs.define('link_trees.index', () => {
  'use strict';

  class LinkTreesIndex {
    constructor(options) {
      this.grid = $('[data-gridy]');
      this.options = options;
    }

    create() {
      this.gridy();
    }

    gridy() {
      GridyCommon.gridy(this.grid, {
        find: 'name',
        sortName: 'name',
        template: Templates.link_trees.gridy,
        url: this.options.gridy.url,

        done: () => {
          Tip.init();

          MenuActions.init();
        },

        headers: [
          { name: I18n.t('gridy.headers.name'), value: 'name' },
          { name: I18n.t('gridy.headers.description'), value: 'description' },
          { name: I18n.t('gridy.headers.url'), value: 'slug' },
          { name: I18n.t('gridy.headers.actions'), value: '' },
        ],
      });
    }
  }

  return LinkTreesIndex;
});
