import CreditCard from '@/modules/credit_card';
import FormValidator from '@/modules/form_validator';
import Helper from '@/modules/helper';
import Masker from '@/modules/masker';
import Phoner from '@/modules/phoner';
import Switcher from '@/modules/switcher';
import Zipcoder from '@/modules/zipcoder';

export default class Checkout {
  constructor(options) {
    this.contentBoleto = $('[data-content="boleto"]');
    this.contentCreditCard = $('[data-content="credit_card"]');
    this.contentPix = $('[data-content="pix"]');
    this.customerCPF = document.querySelector('[data-customer-cpf-field]');
    this.form = $('[data-form]');
    this.options = options;
    this.paymentMethodField = $('[data-payment-method-field]');
    this.paymentStep = $('[data-payment-step]');
    this.phoneField = document.querySelector('[data-phone]');

    this.boletoValidatedFields = this.contentBoleto.find('[data-validaty]');
    this.creditCardValidatedFields = this.contentCreditCard.find('[data-validaty]');
    this.pixValidatedFields = this.contentPix.find('[data-validaty]');
  }

  bindSwitcher() {
    new Switcher({ callback: this.onSwitcherCallback.bind(this) }).create();
  }

  charge() {
    this.formValidator.showModal('Processando o pagamento, aguarde...');

    $.ajax({ data: this.form.serialize(), type: 'POST', url: this.options.charge_url })
      .done(this.onCharge.bind(this))
      .fail(this.formValidator.showErrors.bind(this.formValidator));
  }

  cpf() {
    if (this.customerCPF) {
      Masker.cpf(this.customerCPF);
    }
  }

  create() {
    this.formValidator = new FormValidator({ form: this.form, onValid: this.onValid.bind(this) }).create();

    this.bindSwitcher();
    this.cpf();
    this.phoner();

    new Zipcoder({ scope: 'payment' }).create();

    this.creditCard();
  }

  creditCard() {
    if (this.contentCreditCard.length) {
      this.creditCard = new CreditCard({
        fail: () => {
          this.formValidator.resetState();
        },

        success: this.charge.bind(this),
      }).create();
    }
  }

  isCreditCard() {
    return this.paymentMethodField.val() === 'credit_card';
  }

  onCharge(json) {
    Helper.goTo(this.options.redirect_url.replace(':id', json.id));
  }

  onSwitcherCallback(payment_method) {
    this.paymentMethodField.val(payment_method);
    this.paymentStep.find('[data-validaty-ignore]').removeAttr('data-validaty-ignore');

    const fieldsToIgnore = {
      boleto: this.creditCardValidatedFields,
      credit_card: this.boletoValidatedFields,
      pix: this.creditCardValidatedFields,
    }[payment_method];

    // Boleto has no more specific fields.
    if (fieldsToIgnore) {
      for (let i = 0; i < fieldsToIgnore.length; i++) {
        const field = fieldsToIgnore[i];

        field.dataset.validatyIgnore = field.dataset.validaty;
      }
    }
  }

  onValid() {
    this.isCreditCard() ? this.creditCard.createToken() : this.charge();
  }

  phoner() {
    if (this.phoneField) {
      Phoner.init({ field: this.phoneField });
    }
  }
}
