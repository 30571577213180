import modulejs from 'modulejs';

import Commenter from '@/modules/commenter';
import Gallery from '@/modules/gallery';
import GradeTree from '@/modules/grade_tree';
import Hearting from '@/modules/hearting';
import Map from '@/modules/map';
import MenuActions from '@/modules/menu_actions';
import Phones from '@/modules/phones';
import Rating from '@/modules/rating';
import Voting from '@/modules/voting';

modulejs.define('units.show', () => {
  'use strict';

  function Module(options) {
    this.options = options;
  }

  Module.prototype = {
    voting: function () {
      new Voting(
        $.extend({}, this.options.voting.comment, {
          voteableType: 'Comment',
          wrapper: '.comment__actions',
        })
      ).create();
    },

    comment: function () {
      $.extend({}, this.options.voting.comment, {
        voteableType: 'Comment',
        wrapper: '.comment__actions',
      }).create();
    },

    create: function () {
      new GradeTree().create();
      new Phones().create();
      MenuActions.init();
      new Rating(this.options.rating).create();
      new Gallery().bindPhotos();
      this.hearting();
      new Commenter().create();
      this.voting();
      new Map(this.options.map).create();
    },

    hearting: function () {
      new Hearting({ voteableType: 'Unit', wrapper: '.profile__sidebar__body' }).init();
    },
  };

  return function (options) {
    return new Module(options);
  };
});
