export default class ActiveStoragePreview {
  constructor(options) {
    this.options = options;
    this.uploadFields = document.querySelectorAll('[data-direct-upload-url]');
    this.buttons = document.querySelectorAll('[data-upload-button]');
  }

  bindButton() {
    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];

      button.addEventListener('click', (event) => {
        const field = event.currentTarget.closest('[data-upload-wrapper]').querySelector('[data-direct-upload-url]');

        field.click();
      });
    }
  }

  bindField() {
    for (let i = 0; i < this.uploadFields.length; i++) {
      const field = this.uploadFields[i];
      const target = field.closest('[data-upload-wrapper]');

      field.addEventListener('change', (event) => {
        const files = event.currentTarget.files;

        let readers = [];

        for (let i = 0; i < files.length; i++) {
          readers.push(this.readAndPreview(files[i]));
        }

        Promise.all(readers).then((values) => {
          for (let i = 0; i < values.length; i++) {
            const { file, base64 } = values[i];

            this.onLoadPreview(target, file, field, base64);
          }
        });
      });
    }
  }

  bindUpload() {
    addEventListener('direct-upload:initialize', this.directUploadInitialize.bind(this));
    addEventListener('direct-uploads:start', this.directUploadsStart.bind(this));
    addEventListener('direct-upload:start', this.directUploadStart.bind(this));
    addEventListener('direct-upload:before-blob-request', this.directUploadBeforeBlobRequest.bind(this));
    addEventListener('direct-upload:before-storage-request', this.directUploadBeforeStorageRequest.bind(this));
    addEventListener('direct-upload:progress', this.directUploadProgress.bind(this));
    addEventListener('direct-upload:error', this.directUploadError.bind(this));
    addEventListener('direct-upload:end', this.directUploadEnd.bind(this));
    addEventListener('direct-uploads:end', this.directUploadsEnd.bind(this));
  }

  directUploadInitialize(event) {
    console.log('[upload.1] Dispatched for every file after form submission.');

    this.triggerEvent('active-storage-preview:initialize');
  }

  directUploadsStart(event) {
    console.log('[upload.2] A form containing files for direct upload fields was submitted.');

    this.triggerEvent('active-storage-preview:all-start');
  }

  directUploadStart(event) {
    console.log('[upload.3] A direct upload is starting.');

    this.triggerEvent('active-storage-preview:start');
  }

  directUploadBeforeBlobRequest(event) {
    console.log('[upload.4] Before making a request to your application for direct upload metadata.');

    this.triggerEvent('active-storage-preview:before-blob-request');
  }

  directUploadBeforeStorageRequest(event) {
    console.log('[upload.5] Before making a request to store a file.');

    this.triggerEvent('active-storage-preview:before-storage-request');
  }

  directUploadProgress(event) {
    console.log('[upload.6] As requests to store files progress.');

    const id = this.parameterize(event.detail.file.name);
    const percentBox = this.percentBox(event, id);

    if (percentBox) {
      percentBox.innerText = `${parseInt(event.detail.progress, 10)}%`;
    }

    this.triggerEvent('active-storage-preview:progress');
  }

  directUploadError(event) {
    console.log('[upload.7] An error occurred. An alert will display unless this event is canceled.');

    event.preventDefault(event.detail.error);

    this.triggerEvent('active-storage-preview:error');
  }

  directUploadEnd(event) {
    console.log('[upload.8] A direct upload has ended.');

    const id = this.parameterize(event.detail.file.name);
    const percentBox = this.percentBox(event, id);

    if (percentBox) {
      percentBox.classList.remove('visible');
    }

    this.triggerEvent('active-storage-preview:end');
  }

  directUploadsEnd(event) {
    console.log('[upload.9] All direct uploads have ended.');

    this.triggerEvent('active-storage-preview:all-end');
  }

  init() {
    this.bindButton();
    this.bindField();
    this.bindUpload();

    return this;
  }

  onLoadPreview(target, file, field, base64) {
    const id = this.parameterize(file.name);

    if (field.multiple) {
      const html = this.previewTemplate(base64, id, file);

      target.querySelector('[data-upload-images]').insertAdjacentHTML('beforeend', html);
    } else {
      if (target.dataset.uploadAttribute === 'style') {
        target.style = `background-image: url("${base64}")`;
      } else {
        target.querySelector('[data-upload-preview-image]').src = base64;
      }
    }

    this.triggerEvent('active-storage-preview:on-load-preview');
  }

  parameterize(value) {
    return value
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/, '')
      .replace(/\s/g, '-');
  }

  percentBox(event, id) {
    const box =
      document.querySelector(`[data-preview-item="${id}"]`) ||
      event.target.closest('[data-upload-wrapper]').querySelector('[data-preview-item]');

    if (box) {
      box.classList.add('visible');
    }

    return box;
  }

  // TODO: try to not change the original template when we replace string it should be a copy.
  previewTemplate(src, id, file) {
    return `
      <div class="photos__link photos__preview" data-upload-template>
        <div class="photos__preview__loading" data-preview-item="${id}">0%</div>
        <img alt="" class="photos__image photos__preview__image" src="${src}">
      </div>
    `;
  }

  readAndPreview(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve({ file: file, base64: reader.result });
      };

      reader.onerror = () => {
        reject(reader);
      };

      reader.readAsDataURL(file);
    });
  }

  triggerEvent(name) {
    dispatchEvent(new Event(name));
  }
}
