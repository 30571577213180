import modulejs from 'modulejs';

import DarkMode from '@/modules/dark_mode';
import Gallery from '@/modules/gallery';
import Phoner from '@/modules/phoner';
import Uploader from '@/modules/uploader';
import UserAccount from '@/modules/user_account';
import Zipcoder from '@/modules/zipcoder';

modulejs.define('settings.form', () => {
  'use strict';

  class SettingsForm {
    create() {
      Phoner.init({ nestedy: document.querySelector('[data-phones]') });

      DarkMode.init();
      Uploader.init();

      new Gallery().bindForm();
      new UserAccount().init();

      new Zipcoder({ scope: 'personal' }).create();
    }
  }

  return SettingsForm;
});
