import dayjs from 'dayjs';

import Calendar from '@/modules/calendar';
import Masker from '@/modules/masker';

export default class UserAccount {
  constructor() {
    this.birthdate = document.querySelector('#user_birthdate');
    this.cpf = document.querySelector('#user_cpf');
  }

  calendars() {
    Calendar.init({
      maxDate: dayjs().add(-5, 'y').toDate(),
      minDate: dayjs().add(-80, 'y').toDate(),
    });
  }

  init() {
    Masker.cpf(this.cpf);

    this.calendars();

    return this;
  }
}
