export default () => {
  const fields = document.querySelectorAll('[data-notify]');

  if (!fields.length) {
    return;
  }

  let options = {};

  fields.forEach((field) => {
    options[field.name] = field.value;
  });

  $.notify(options);
};
