import Autocomplete from '@/modules/autocomplete';

const Templates = require('@/pages/common/templates');

export default class Tag {
  constructor(options) {
    this.options = options;
  }

  addItem(object) {
    const value = object[this.options.valueAttribute];

    const html = Templates[this.options.template].tag({
      uid: value,
      name: this.options.name,
      remove_image: this.options.removeImage,
      text: object[this.options.textAttribute],
      value: value,
    });

    $(html).appendTo(this.options.wrapper);
  }

  bindAutocomplete() {
    new Autocomplete({
      field: this.options.field,

      options: {
        keys: this.options.keys,

        templates: {
          none: Templates[this.options.template].search.none,
          search: Templates[this.options.template].search,
        },

        prevent: ['selected'],
      },
    }).create();

    this.options.field.on('complety:selected', (_evt, suggestion) => {
      this.tag(suggestion);
    });
  }

  bindDestroy() {
    var that = this;

    this.options.wrapper.on('click', '.tag__destroy', function () {
      that.destroy(this);
    });
  }

  create() {
    if (this.options.field) {
      this.bindAutocomplete();
    }

    this.bindDestroy();

    return this;
  }

  destroy(button) {
    var tag = $(button).parent();

    tag.remove();

    this.options.wrapper.trigger('tag:removed', tag);
  }

  tag(object) {
    var uid = object[this.options.valueAttribute];
    var element = this.options.wrapper.find('[data-tag-uid="' + uid + '"]');

    if (element.length) {
      element.hide().fadeIn();
    } else {
      this.addItem(object);
    }

    if (this.options.field) {
      this.options.field.val('').trigger('focus');
    }
  }
}
