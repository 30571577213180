import Helper from '@/modules/helper';

export default class FormValidator {
  constructor(options) {
    this.errors = $('[data-error]');
    this.form = options.form;
    this.globalError = $('[data-global-error]');
    this.modal = $('[data-modal]');
    this.modalBody = $('[data-modal-body]');
    this.options = options;
    this.submitButton = $('[data-submit]');
  }

  bindModal() {
    this.modal.modaly({ closeButton: false, closeOverlay: false, esc: false, speed: 0 });
  }

  bindSubmit() {
    this.submitButton.on('click', () => {
      this.cleanErrors();
      this.form.submit();
    });
  }

  bindValidation() {
    this.form.validaty({
      errorTarget: this.errorTarget.bind(this),
      onInvalid: this.onInvalid.bind(this),
      onValid: this.onValid.bind(this),
    });
  }

  cleanErrors() {
    this.errors.empty();
    this.globalError.hide().empty();
  }

  closeModal() {
    this.modal.modaly('close');
  }

  create() {
    this.bindSubmit();
    this.bindModal();
    this.bindValidation();

    return this;
  }

  errorTarget(field, message) {
    const errorEl = field[0].parentElement.querySelector('[data-error]');

    if (errorEl) {
      errorEl.appendChild(message[0]);
    } else {
      message.insertAfter(field[0]);
    }
  }

  onInvalid(_fields, evt) {
    evt.preventDefault();

    this.resetState();

    const firstError = this.form[0].querySelector('[id^="validaty-"]');

    Helper.scrollTo(firstError);

    if (this.options.onInvalid) {
      this.options.onInvalid();
    }
  }

  onValid(fields, evt) {
    evt.preventDefault();

    if (this.options.onValid) {
      this.options.onValid();
    }
  }

  resetState() {
    // FIXME: it does not works without timeout
    setTimeout(() => {
      this.closeModal();

      for (let i = 0; i < this.submitButton.length; i++) {
        this.submitButton[i].disabled = false;
        this.submitButton[i].innerText = this.submitButton[i].dataset.originalText;
      }
    }, 1000);
  }

  showErrors(response) {
    const json = response.responseJSON;

    if (json) {
      const errors = json.errors;

      if (errors) {
        this.showFieldErrors(errors);
      }
    } else if (response.responseText) {
      $.notify({ title: `${response.responseText.substring(0, 80)}...` });
    } else {
      $.notify({ title: I18n.t('api_error') });
    }

    this.resetState();
  }

  showFieldErrors(errors) {
    if (typeof errors === 'object') {
      for (let i = 0; i < errors.length; i++) {
        const placeholder = $('[data-' + errors[i].id + '-error]');

        if (placeholder.length) {
          placeholder.append(errors[i].message);

          if (i === 0) {
            Helper.scrollTo(placeholder.show());
          }
        } else {
          $.notify({ title: errors[i].message });
        }
      }
    } else {
      $.notify({ title: errors });
    }

    this.resetState();
  }

  showModal(body) {
    this.modalBody.html(body);
    this.modal.modaly('open');
  }
}
