import modulejs from 'modulejs';

import Product from '@/modules/product';

modulejs.define('products.show', () => {
  'use strict';

  class ProductsShow {
    create() {
      new Product().create();
    }
  }

  return ProductsShow;
});
