import Formater from '@/modules/formater';

export default class Product {
  constructor() {
    this.minusButtons = document.querySelectorAll('[data-product-minus]');
    this.moreButtons = document.querySelectorAll('[data-product-more]');
    this.productQuantityFields = document.querySelectorAll('[data-product-quantity]');
    this.quantityFields = document.querySelectorAll('[data-product-quantity]');
    this.submitButton = document.querySelector('[data-submit]');
    this.totalField = document.querySelector('[data-product-total]');
  }

  bind() {
    for (const button of this.minusButtons) {
      this.bindMinusButton(button);
    }

    for (const button of this.moreButtons) {
      this.bindMoreButton(button);
    }
  }

  bindMinusButton(button) {
    button.addEventListener('click', (e) => {
      const context = this.getContext(e.currentTarget);

      if (context.value > 0) {
        context.quantityField.value = context.value - 1;

        this.calculate();
      }

      this.updateMinusButtonState(e.currentTarget);
    });
  }

  bindMoreButton(button) {
    button.addEventListener('click', (e) => {
      const context = this.getContext(e.currentTarget);

      if (context.value < context.limit && context.value < context.available) {
        context.quantityField.value = context.value + 1;

        this.calculate();
      }

      this.updateMoreButtonState(e.currentTarget);
    });
  }

  calculate() {
    let total = 0;

    for (const field of this.quantityFields) {
      total += field.value * field.dataset.amount;
    }

    if (this.totalField) {
      this.totalField.innerText = Formater.money(total);
    }

    if (this.submitButton) {
      this.submitButton.disabled = this.productsQuantity() <= 0;
    }
  }

  create() {
    this.bind();
    this.calculate();

    this.updateMinusButtonsState();
    this.updateMoreButtonsState();
  }

  disable(button) {
    button.classList.add('product__quantity__icon--disabled');
  }

  enable(button) {
    button.classList.remove('product__quantity__icon--disabled');
  }

  getContext(el) {
    const parent = el.parentNode;
    const quantityField = parent.querySelector('[data-product-quantity]');
    const availableField = parent.querySelector('[data-available]');
    const limitField = parent.querySelector('[data-limit]');

    return {
      available: parseInt(availableField.dataset.available || 9999999, 10),
      limit: parseInt(limitField.dataset.limit || 9999999, 10),
      minusButton: parent.querySelector('[data-product-minus]'),
      moreButton: parent.querySelector('[data-product-more]'),
      quantityField: quantityField,
      value: parseInt(quantityField.value, 10),
    };
  }

  productsQuantity() {
    let quantity = 0;

    for (const field of this.productQuantityFields) {
      quantity += field.value;
    }

    return quantity;
  }

  updateMinusButtonState(button) {
    const context = this.getContext(button);

    if (context.value <= 0) {
      this.disable(context.minusButton);
    }

    if (context.value < context.limit && context.value < context.available) {
      this.enable(context.moreButton);
    }
  }

  updateMinusButtonsState() {
    for (const button of this.minusButtons) {
      this.updateMinusButtonState(button);
    }
  }

  updateMoreButtonState(button) {
    const context = this.getContext(button);

    if (context.value > 0) {
      this.enable(context.minusButton);
    }

    if (context.value >= context.limit || context.value >= context.available) {
      this.disable(context.moreButton);
    }
  }

  updateMoreButtonsState() {
    for (const button of this.moreButtons) {
      this.updateMoreButtonState(button);
    }
  }
}
