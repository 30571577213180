const displayError = (error) => {
  let options = {};

  if (error.message) {
    console.warn('Attribute `error.message` is deprecated, use: `error.title` or `error.body`.');
  }

  options.body = error.body || error.message;
  options.title = error.title;

  if (options.body || options.title) notify(options);
};

const notify = (options) => $.notify(options);

const status302 = (json) => {
  status(json);

  if (json.url) {
    let timeout = json.timeout || 1000;

    setTimeout(() => (window.location.href = json.url), timeout);
  }
};

const status = (json) => {
  if (json.notify) {
    notify(json.notify);
  } else if (json.message) {
    notify({ title: json.message });
  } else if (json.error) {
    displayError(json.error);
  } else if (json.errors) {
    for (const error of json.errors) {
      displayError(error);
    }
  }
};

export const ajaxHandle = (xhr) => {
  const json = xhr.responseJSON || { notify: { details: xhr.responseText, title: 'Houston, we have a problem!' } };

  switch (xhr.status) {
    case 302:
      status302(json);
      break;

    case 401:
      window.location.href = '/login';
      break;

    default:
      status(json);
  }
};

export const bindAjaxComplete = () => {
  $(document).ajaxComplete((_event, xhr) => {
    ajaxHandle(xhr);
  });
};
