import Masker from '@/modules/masker';

export default class Zipcoder {
  constructor(options) {
    this.options = options;
    this.wrapper = document.querySelector(`[data-address="${options.scope}"]`);
    this.xhrs = [];
  }

  abort() {
    for (var i = 0; i < this.xhrs.length; i++) {
      this.xhrs[i].abort();
    }

    this.xhrs = [];
  }

  ajax(url) {
    if (this.xhrs.length) {
      this.abort();
    }

    const ajax = $.ajax(url);
    const that = this;

    this.xhrs.push(ajax);

    this.loading('start');
    this.lock(true);

    ajax.fail(that.fail.bind(this));
    ajax.always(this.always.bind(this));

    return ajax;
  }

  always() {
    this.loading('stop');
    this.lock(false);
  }

  bind() {
    this.zipCode.addEventListener('keypress', this.prevent.bind(this));
    this.zipCode.addEventListener('keyup', this.search.bind(this, false));
    this.zipCode.addEventListener('paste', this.search.bind(this, true));
  }

  clear() {
    const inputs = this.wrapper.querySelectorAll('input');

    for (let i = 0; i < inputs.length; i++) {
      const field = inputs[i];

      if (field.hidden === false) {
        field.value = '';
      }
    }
  }

  create() {
    if (!this.wrapper) {
      return;
    }

    this.setVariables(this.wrapper);
    this.bind();

    Masker.zipCode(this.zipCode);
    Masker.number(this.number, { max: 5, min: 1 });
    Masker.state(this.state);
  }

  fail(json) {
    if (json.statusText !== 'abort') {
      $.notify({
        body: json.status,
        details: json.responseText,
        title: json.statusText,
      });
    }
  }

  key(evt) {
    return evt.keyCode || evt.charCode;
  }

  loading(action) {
    this.zipCode.parentElement.classList[action === 'start' ? 'add' : 'remove']('loading');
  }

  lock(boo) {
    this.fields.forEach((field) => {
      field.readOnly = boo;
    });
  }

  isNumber(evt) {
    const key = this.key(evt);

    return key >= 48 && key <= 57;
  }

  isSearch(paste, evt) {
    return (paste || this.isNumber(evt)) && this.rawZipCode().length === 8;
  }

  populate(json) {
    // We had some error, let's the global bind get it.
    if (json.notify) {
      return;
    }

    this.clear();

    this.zipCode.value = json.zip_code;

    this.number.focus();

    this.street.value = json.street;
    this.neighborhood.value = json.neighborhood;
    this.city.value = json.city;
    this.state.value = json.state;

    if (this.complement) {
      this.complement.value = json.complement;
    }

    if (this.options.callback) {
      this.options.callback();
    }
  }

  prevent(evt) {
    if (!this.isNumber(evt)) {
      evt.preventDefault();

      return false;
    }
  }

  rawZipCode() {
    return this.zipCode.value.replace('_', '').replace('-', '');
  }

  search(paste, evt) {
    if (this.isSearch(paste, evt)) {
      setTimeout(() => {
        this.ajax(`/addresses/search/${this.rawZipCode()}`).done(this.populate.bind(this));
      }, 200);
    }
  }

  setVariables(wrapper) {
    this.city = wrapper.querySelector('[data-city-field]');
    this.complement = wrapper.querySelector('[data-complement-field]');
    this.fields = wrapper.querySelectorAll('input');
    this.neighborhood = wrapper.querySelector('[data-neighborhood-field]');
    this.number = wrapper.querySelector('[data-number-field]');
    this.state = wrapper.querySelector('[data-state-field]');
    this.street = wrapper.querySelector('[data-street-field]');
    this.zipCode = wrapper.querySelector('[data-zip-code-field]');

    this.model = this.zipCode.name.split('[')[0];
  }
}
