import modulejs from 'modulejs';

import Sortable from '@/modules/sortable';
import Uploader from '@/modules/uploader';

modulejs.define('link_trees.form', () => {
  'use strict';

  class LinkTreesForm {
    constructor() {
      this.item = document.querySelector('[data-nestedy]');
      this.links = $('[data-links]');
    }

    create() {
      this.nestedy();
      this.sortable();

      Uploader.init();
    }

    nestedy() {
      this.links.nestedy({
        clone: false,
        clear: function () {
          return this.type !== 'hidden';
        },
        destroy: true,

        excludes: function () {
          return /\[id\]$/.test(this.name) || this.className === 'message--error';
        },

        onAdd: () => {
          this.reorderNestedyItems();
        },
      });
    }

    reorderNestedyItems() {
      var items = document.querySelectorAll('[data-row-order]');

      for (var i = 0; i < items.length; i++) {
        items[i].value = i;
      }
    }

    sortable() {
      new Sortable(this.item, {
        animation: 50,
        ghostClass: 'sortable__ghost',
        handle: '[data-drag]',
        onEnd: (event) => {
          this.reorderNestedyItems();
        },
      });
    }
  }

  return LinkTreesForm;
});
