import I18n from '@/modules/i18n';

import { isNotificationSupported } from '@/modules/helper';

export default class Notifier {
  constructor(options) {
    this.options = options;
  }

  async ask() {
    if (!isNotificationSupported()) return console.log('[Notifier]', 'Notification not supported!');

    if (!this.isDenied() && !confirm(I18n.t('push_notification.confirm_question'))) return;

    await Notification.requestPermission();

    console.log('[Notifier]', 'requestPermission:', this.status());

    this.check();
  }

  check() {
    const methods = {
      default: this.ask,
      denied: this.options.denied,
      granted: this.options.granted,
    };

    methods[this.status()]();

    this.options?.finally();
  }

  isDefault() {
    return this.status() === 'default';
  }

  isDenied() {
    return this.status() === 'denied';
  }

  isGranted() {
    return this.status() === 'granted';
  }

  isUnavailable() {
    return this.status() === undefined;
  }

  status() {
    if (isNotificationSupported()) return Notification.permission;

    console.log('[Notifier]', 'Notification not supported!');
  }

  statusText() {
    const status = this.status() || 'unsupported';

    return I18n.t(`push_notification.status.${status}`);
  }
}
