import Notifier from '@/modules/notifier';

import { metaValue, isIOS } from '@/modules/helper';
import { registerDevice } from '@/modules/firebase';

const notifier = new Notifier();

const bindInstall = () => {
  const button = document.querySelector('[data-install-app]');

  if (!button) return;

  if (isIOS()) {
    const modal = $('[data-ios-modal]');

    modal.modaly({ closeTarget: '[data-ios-close]', showClass: 'show' });

    button.addEventListener('click', () => modal.modaly('open'));

    return;
  }

  let deferredPrompt;

  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();

    deferredPrompt = event;
  });

  button.addEventListener('click', async () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();

    const { outcome } = await deferredPrompt.userChoice;

    if (outcome === 'accepted') deferredPrompt = null;
  });
};

const getRegistration = async () => await navigator.serviceWorker.ready;

const getSubscription = async (registration) => {
  console.log('[ServiceWorker]', 'getSubscription...');

  if (!('PushManager' in window)) {
    console.log('[ServiceWorker]', 'pushManager not supported!');
  }

  try {
    const subscription = await registration.pushManager.getSubscription();

    if (subscription) {
      console.log('[ServiceWorker]', 'getSubscription! (hit)');

      return subscription;
    }

    console.log('[ServiceWorker]', 'getSubscription! (miss)');
  } catch (error) {
    console.log('[ServiceWorker]', 'getSubscription failed', error);

    throw error;
  }
};

const register = async () => {
  console.log('[ServiceWorker]', 'register...');

  if (!('serviceWorker' in navigator)) return console.log('[ServiceWorker]', 'serviceWorker not supported!');

  try {
    const registration = await navigator.serviceWorker.register('/serviceworker.js');

    console.log('[ServiceWorker]', 'register!', registration.scope);
  } catch (error) {
    console.log('[ServiceWorker]', 'register failed', error);
  }
};

const subscribe = async () => {
  console.log('[ServiceWorker]', 'subscribe...');

  const registration = await getRegistration();
  const subscription = await getSubscription(registration);

  if (subscription) return;

  const applicationServerKey = metaValue('firebase_public_vapid_key');
  const userVisibleOnly = true;

  try {
    await registration.pushManager.subscribe({ applicationServerKey, userVisibleOnly });

    await registerDevice();

    console.log('[ServiceWorker]', 'subscribe!');
  } catch (error) {
    console.log('[ServiceWorker]', 'subscribe failed', error);
  }
};

export const init = () => {
  register();

  bindInstall();

  if (notifier.isGranted()) subscribe();
};

export const unsubscribe = async () => {
  try {
    const registration = await getRegistration();
    const subscription = await getSubscription(registration);

    const result = await subscription.unsubscribe();

    return result;
  } catch (error) {
    console.log('[ServiceWorker]', 'subscribe failed', error);
  }
};
