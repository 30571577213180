import CreditCard from '@/modules/credit_card';
import FormValidator from '@/modules/form_validator';
import Helper from '@/modules/helper';
import Masker from '@/modules/masker';
import Phoner from '@/modules/phoner';
import Zipcoder from '@/modules/zipcoder';

export default class PaymentMethod {
  constructor(options) {
    this.customerCPF = document.querySelector('[data-customer-cpf-field]');
    this.form = $('[data-form]');
    this.options = options;
  }

  applyMask() {
    Masker.cpf(this.customerCPF);
  }

  bindZipcode() {
    new Zipcoder({ scope: 'payment' }).create();
  }

  create() {
    this.applyMask();

    Phoner.init();

    this.bindZipcode();
    this.initValidation();
    this.initCreditCard();
  }

  initCreditCard() {
    this.creditCard = new CreditCard({
      fail: () => {
        this.formValidator.resetState();
      },

      success: this.save.bind(this),
    }).create();
  }

  initValidation() {
    this.formValidator = new FormValidator({ form: this.form, onValid: this.onValid.bind(this) }).create();
  }

  onValid() {
    this.creditCard.createToken();
  }

  save() {
    this.formValidator.showModal('Salvando os dados, aguarde...');

    $.ajax({ data: this.form.serialize(), type: this.options.save_verb || 'PATCH', url: this.options.save_url })
      .done(this.success.bind(this))
      .fail(this.formValidator.showErrors.bind(this.formValidator));
  }

  success(_json) {
    setTimeout(() => {
      Helper.goTo(this.options.success_url);
    }, 1000);
  }
}
