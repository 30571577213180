import modulejs from 'modulejs';

import PaymentAccount from '@/modules/payment_account';

modulejs.define('payment_accounts.form', () => {
  'use strict';

  class PaymentAccountsForm {
    create() {
      new PaymentAccount().init();
    }
  }

  return PaymentAccountsForm;
});
