export default class Voting {
  constructor(options) {
    this.clickActive = true;
    this.heart = document.querySelector('[data-heart]');
    this.options = options;
  }

  init() {
    this.heart.addEventListener('click', this.onClick.bind(this));
  }

  onClick(evt) {
    if (!this.clickActive) return;

    this.clickActive = false;

    const heart = evt.currentTarget;

    const ajax = $.ajax({
      data: { value: heart.dataset.value, voteable_type: this.options.voteableType },
      type: 'PATCH',
      url: `/votes/${heart.dataset.voteableId}`,
    });

    ajax.done(() => {
      if (heart.classList.contains('heart--on')) {
        heart.classList.add('heart--off', 'heart--clicked');
        heart.classList.remove('heart--on', 'heart--clicked');
      } else {
        heart.classList.remove('heart--off');
        heart.classList.add('heart--on', 'heart--clicked');
      }

      setTimeout(() => this.clickActive = true, 2000);
    });
  }
}
