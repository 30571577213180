import I18n from '@/modules/i18n';
import Tip from '@/modules/tip';
import Raty from 'raty-js';

export default class Rating {
  constructor(options) {
    this.options = options;
    this.ratingActives = document.querySelectorAll('[data-rating="active"]');
    this.ratingActiveWrapper = document.querySelector('[data-rating-active-wrapper]');
    this.ratingInactives = document.querySelectorAll('[data-rating="inactive"]');
    this.ratingInactiveWrapper = document.querySelector('[data-rating-inactive-wrapper]');
    this.ratingStartButton = document.querySelector('[data-rating-start]');
    this.ratingStopButton = document.querySelector('[data-rating-stop]');
  }

  bind() {
    if (this.ratingStartButton) {
      this.ratingStartButton.addEventListener('click', () => {
        this.ratingActiveWrapper.style.display = 'block';
        this.ratingInactiveWrapper.style.display = 'none';
      });
    }

    if (this.ratingStopButton) {
      this.ratingStopButton.addEventListener('click', () => {
        this.ratingActiveWrapper.style.display = 'none';
        this.ratingInactiveWrapper.style.display = 'block';
      });
    }
  }

  ratingOptions(type) {
    const extra = {};

    const options = {
      hints: [
        I18n.t('rating.hints.basic.one'),
        I18n.t('rating.hints.basic.two'),
        I18n.t('rating.hints.basic.three'),
        I18n.t('rating.hints.basic.four'),
        I18n.t('rating.hints.basic.five'),
      ],

      noRatedMsg: I18n.t('rating.hints.no_rated_msg'),
      space: false,
    };

    if (type === 'inactive') {
      options.readOnly = true;
    } else {
      extra.starOff = this.options.starEmptyOff;
    }

    return { ...options, ...this.options, ...extra };
  }

  rating() {
    const inactiveOptions = this.ratingOptions('inactive');

    for (let i = 0; i < this.ratingInactives.length; i++) {
      new Raty(this.ratingInactives[i], inactiveOptions).init();
    }

    const activeOptions = this.ratingOptions();

    for (let i = 0; i < this.ratingActives.length; i++) {
      new Raty(this.ratingActives[i], activeOptions).init();
    }
  }

  create() {
    this.bind();
    this.rating();

    Tip.init();
  }
}
