{
  "ru": {
    "action": {
      "other": "Действия"
    },
    "activerecord": {
      "attributes": {
        "editor": {
          "email": "Почта",
          "promoter_role_id": "Рулон"
        },
        "ticket_order_item": {
          "checkin": "Регистрироваться"
        },
        "user": {
          "email": "E-mail",
          "name": "Имя"
        },
        "withdraw": {
          "amount_cents": "Ценить",
          "bank_agency": "Агентство",
          "bank_kind": "Тип",
          "bank_number": "Число",
          "message": "Сообщение",
          "paying_at": "Прогноз",
          "status": "Положение дел"
        }
      }
    },
    "approved": {
      "other": "Сдали"
    },
    "dashboard": "панель приборов",
    "datepicker": {
      "format": "dd/MM/yy",
      "next_month": "Следующий месяц",
      "placeholder": "__/__/__",
      "previous_month": "Предыдущий месяц"
    },
    "disapproved": {
      "other": "Не сдали"
    },
    "edit": "редактировать",
    "enrollment": {
      "one": "Регистрация"
    },
    "evaluate": "Оценивать",
    "finish": "завершить",
    "follow": {
      "one": "Фолловер",
      "other": "последователи"
    },
    "graduation": {
      "profile": {
        "not_selected": "Профиль не выбран"
      },
      "role": {
        "not_selected": "Роль не выбрана"
      }
    },
    "gridy": {
      "errors": {
        "record_not_found": "Без результатов."
      },
      "headers": {
        "abr": "Апрель",
        "action": "Действие",
        "actions": "Действия",
        "active": "Активный",
        "ago": "Август",
        "amount": "Стоимость",
        "artistic_name": "Художественное имя",
        "birthdate": "Дата роджения",
        "boleto_cents": "Болето ($)",
        "controller": "Контроллер",
        "course": "Курс",
        "created_at": "Дата создания",
        "credit_card_cents": "Кредит ($)",
        "credit_card_percent": "Кредит (%)",
        "customer_name": "Клиент",
        "date": "Дата",
        "days_before": "Дней до",
        "default": "По умолчанию",
        "description": "Описание",
        "dez": "Декабрь",
        "due_date": "Истекает",
        "email": "E-mail",
        "end_at": "Конец",
        "evaluation_group": "Группа",
        "exam": "Экзамен",
        "examiners": "экзаменаторы",
        "featured": "Признак",
        "fev": "Февраль",
        "function": "Роль",
        "grade": "Группа",
        "group": "Группа",
        "highlight": "Выделение",
        "id": "ID",
        "interval": "Частота",
        "invoice": "Счёт",
        "iugu_account_id": "Iugu Идентификатор учетной записи",
        "iugu_api_token_production": "Iugu токен API (продукт)",
        "iugu_user_token": "Iugu Токен пользователя",
        "jan": "Январь",
        "jul": "Июль",
        "jun": "Июнь",
        "kind": "Тип",
        "level": "Уровень",
        "liquid": "Чистая сумма",
        "mai": "Май",
        "mar": "Март",
        "menu": "Меню",
        "name": "Имя",
        "nov": "Ноябрь",
        "out": "Октябрь",
        "paid_at": "Оплачено в",
        "parent": "Родитель",
        "percentage": "Процент",
        "period": "Период",
        "pix_percent": "Пиксель (%)",
        "plan": {
          "name": "Тариф"
        },
        "presentation": "Презентиция",
        "prices": "Цены",
        "quantity": "Количество",
        "result": "Результат",
        "role": "Роль",
        "school": "Школа",
        "set": "Сентябрь",
        "sold": "Продано",
        "start_at": "Начало",
        "status": "Статус",
        "student": "Ученик",
        "tax": "Налоги",
        "tax_amount_input_cents": "Приход ($)",
        "tax_amount_output_cents": "Расход ($)",
        "tax_percentage_input_cents": "Приход (%)",
        "tax_percentage_output_cents": "Расход (%)",
        "teacher": "Преподаватель",
        "teachers": "учителя",
        "ticket": "Билет",
        "total": "В сумме",
        "total_of_comments": "Комментарии",
        "total_of_votes": "Лайки",
        "trial_days": "Триал",
        "uid": "UID",
        "unit": {
          "name": "Филиал"
        },
        "units": "Филиал",
        "url": "URL",
        "user": {
          "email": "E-mail",
          "name": "Пользователь"
        },
        "uuid": "UUID",
        "value": "Значение",
        "visible": "Видимое"
      },
      "page": {
        "button": {
          "back": "< предыдущая страница",
          "next": "следущая страница >",
          "title": "страница"
        }
      },
      "refresh": {
        "title": "Обновить"
      },
      "search": {
        "label": "Текст запроса",
        "placeholder": "Введите ваш поисковый запрос"
      },
      "status": {
        "full": "Страница {from} с {to} | {total} записей",
        "one": "1 запись найдена",
        "other": "{total} записей найденных",
        "zero": ""
      }
    },
    "lead": {
      "one": "Лидер",
      "other": "лидеры"
    },
    "liquid": "Жидкость",
    "mock": {
      "other": "симуляции"
    },
    "name": "Название",
    "notification": {
      "other": "Уведомления"
    },
    "notify": "регистрировать",
    "payment_method": {
      "short": "Метод"
    },
    "pending": {
      "other": "Ожидающие"
    },
    "permission": {
      "other": "Разрешения"
    },
    "profile": {
      "one": "Профиль"
    },
    "prospect": {
      "other": "исследовал"
    },
    "push_notification": {
      "confirm_question": "Для полноценной работы мы попросим у вас разрешение на уведомления.",
      "denied_message": "Уведомления отключены, чтобы включить авторизацию в вашем браузере.",
      "manual_revoking": "Вам необходимо удалить разрешение прямо из браузера.",
      "status": {
        "default": "Включите уведомления о новых функциях.",
        "denied": "Уведомления заблокированы. Чтобы включить, настройте свой браузер.",
        "granted": "Активные уведомления.",
        "unsupported": "Уведомления не поддерживаются на этом устройстве/браузере/версии."
      }
    },
    "rating": {
      "hints": {
        "basic": {
          "five": "Превосходно",
          "four": "Хороший",
          "one": "Ужасный",
          "three": "Обычный",
          "two": "Плохо"
        },
        "evaluation": {
          "four": "Превосходно",
          "one": "причина отказа",
          "three": "Хороший",
          "two": "Тренировать"
        }
      },
      "no_rated_msg": "Пока нет отзывов."
    },
    "result": {
      "other": "Результаты"
    },
    "role": {
      "one": "Профиль"
    },
    "show": "Смотреть",
    "student": {
      "one": "Ученик"
    },
    "success": "Успех",
    "tax": {
      "one": "Таксоны"
    },
    "teacher": {
      "one": "Преподаватель"
    },
    "today": "Сегодня",
    "total": "Всего",
    "wait": "Ожидайте..."
  }
}