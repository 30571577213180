import notifyWatcher from '@/modules/notify_watcher';

import Menu from '@/modules/menu';
import ModuleLoader from '@/modules/module_loader';
import SearchBar from '@/modules/search_bar';

import { bindAjaxComplete } from '@/modules/ajax_handler';
import { init } from '@/modules/service_worker';

export default () => {
  init();

  const options = ModuleLoader.getOptions();

  ModuleLoader.init(options);

  new Menu().create();
  new SearchBar().create();

  bindAjaxComplete();

  notifyWatcher();
};
