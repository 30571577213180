import en from 'air-datepicker/locale/en';
import ptBr from 'air-datepicker/locale/pt-BR';
import ru from 'air-datepicker/locale/ru';

import AirDatepicker from 'air-datepicker';
import Helper from '@/modules/helper';
import I18n from '@/modules/i18n';
import Masker from '@/modules/masker';

export default class Calendar {
  constructor(options) {
    this.options = options;
  }

  buttons() {
    const today = {
      content: I18n.t('today'),

      onClick: (el) => {
        const date = new Date();

        el.selectDate(date);
        el.setViewDate(date);
      },
    };

    return ['clear', today];
  }

  create() {
    const fieldWrapper = this.options.field.parentElement;
    const maxDate = this.options.maxDate;
    const minDate = this.options.minDate;
    const updates = {};

    if (maxDate) {
      updates.maxDate = maxDate;
    }

    if (minDate) {
      updates.minDate = minDate;
    }

    this.datepicker = new AirDatepicker(this.options.field, this.configuration());

    if (fieldWrapper) {
      const button = fieldWrapper.querySelector('svg');

      if (button) {
        button.addEventListener('click', () => this.datepicker.show());
      }
    }

    Masker.date(this.options.field);
  }

  configuration() {
    const options = {
      autoClose: true,
      buttons: this.buttons(),
      clear: I18n.t('clear'),
      dateFormat: I18n.t('datepicker.format'),
      isMobile: false,
      locale: this.locale(),

      onSelect: (data) => {
        const el = data.datepicker.$el;
        const wrapper = Helper.closestData(el, 'wrapper');
        const nextField = wrapper.querySelector(`[data-time="${el.dataset.next}"]`);

        nextField?.focus();
      },

      showEvent: false,
      timepicker: false,
    };

    return $.extend({}, options, this.options);
  }

  locale() {
    return { 'en': en, 'pt-BR': ptBr, 'ru': ru }[I18n.locale];
  }

  static init(options = {}) {
    const wrapper = options.wrapper || document;
    const fields = options.field ? [options.field] : wrapper.querySelectorAll('[data-date]');

    for (let i = 0; i < fields.length; i++) {
      options.field = fields[i];

      new Calendar(options).create();

      if (options.onEnter) {
        this.#bindEnter(options);
      }
    }
  }

  static #bindEnter(options) {
    options.field.addEventListener('keydown', (evt) => {
      const key = evt.keyCode || evt.which;

      if (key === 13) {
        evt.preventDefault();

        options.onEnter.call(this, options.field);
      }
    });
  }
}
