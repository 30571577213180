import modulejs from 'modulejs';

import Hearting from '@/modules/hearting';
import MenuActions from '@/modules/menu_actions';

modulejs.define('categories.show', () => {
  'use strict';

  class CategoriesShow {
    constructor(options) {
      this.options = options;
    }

    create() {
      this.hearting();

      MenuActions.init();
    }

    hearting() {
      new Hearting({ voteableType: 'Category', wrapper: '.profile__sidebar__body' }).init();
    }
  }

  return CategoriesShow;
});
