import modulejs from 'modulejs';

import Gallery from '@/modules/gallery';
import MenuActions from '@/modules/menu_actions';

modulejs.define('users.show', () => {
  'use strict';

  class UsersShow {
    create() {
      MenuActions.init();
      new Gallery().bindPhotos();
    }
  }

  return UsersShow;
});
