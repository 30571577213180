export default class MenuActions {
  constructor() {
    this.body = document.body;
    this.buttons = document.querySelectorAll('[data-menu-actions-button]');
    this.menus = document.querySelectorAll('[data-menu-actions]');
  }

  static init() {
    new MenuActions().init();
  }

  closeAll(self) {
    for (const menu of this.menus) {
      if (menu != self) menu.classList.remove('menu-actions--open');
    }
  }

  init() {
    this.body.addEventListener('click', (evt) => this.closeAll(evt.currentTarget));

    for (const button of this.buttons) {
      button.addEventListener('click', (evt) => {
        evt.stopPropagation();

        const currentMenu = evt.currentTarget.parentNode;

        this.closeAll(currentMenu);

        currentMenu.classList.toggle('menu-actions--open');
      });
    }
  }
}
