import { enableScroll } from '@/modules/helper';
import { isSm } from '@/modules/screen';

export default class Menu {
  constructor() {
    this.button = $('[data-navbar-button]');
    this.closeMobileMenu = $('[menu-mobile-close]');
    this.dropdown = $('[menu-drop-down]');
    this.mobileMenu = $('[menu-mobile]');
    this.overlay = $('[overlay]');
  }

  bind() {
    this.button.on('click', this.toggle.bind(this));
    this.closeMobileMenu.on('click', this.toggle.bind(this));
    this.overlay.on('click', this.toggle.bind(this));
  }

  create() {
    this.bind();
  }

  toggle() {
    enableScroll(this.mobileMenu.hasClass('menu__dropdown--active'));

    if (isSm()) {
      this.dropdown.toggleClass('menu__dropdown--active');
      this.overlay.fadeToggle(100);
    } else {
      this.mobileMenu.toggleClass('menu__dropdown--active');
    }
  }
}
