import modulejs from 'modulejs';

export default class ModuleLoader {
  static getOptions() {
    const params = document.querySelectorAll('[data-param]');
    const options = {};

    params.forEach((field) => {
      const paths = field.name.split('.');
      const value = this.#parseValue(field.value);

      let virtual = options;

      paths.forEach((path, index) => {
        if (virtual[path] === undefined) {
          virtual[path] = {};
        }

        if (index + 1 === paths.length) {
          virtual[path] = value;
        } else {
          virtual = virtual[path];
        }
      });
    });

    return options;
  }

  static init(opt) {
    const options = opt || this.getOptions();
    const moduleName = document.querySelector('body').dataset.module;

    if (!moduleName) {
      return;
    }

    moduleName.split(' ').forEach((name) => {
      if (modulejs.state().hasOwnProperty(name)) {
        const Module = modulejs.require(name);
        const module = new Module(options);

        if (module && module.create) {
          module.create();
        }
      }
    });
  }

  static #isBoolean(value) {
    return value === 'true' || value === 'false';
  }

  static #isNumber(value) {
    return typeof value === 'number' && isFinite(value);
  }

  static #parseValue(value) {
    if (this.#isNumber(value)) {
      return parseFloat(value);
    } else if (this.#isBoolean(value)) {
      return value === 'true';
    }

    return value;
  }
}
