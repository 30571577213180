// TODO: use a proper lib for it
export default class Formater {
  static money(value, type) {
    'use strict';

    const divisor = type == 'decimal' ? 1 : 100.0;

    return 'R$ ' + (value / divisor).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  static number(value, type) {
    'use strict';

    const divisor = type == 'decimal' ? 1 : 100.0;

    return (value / divisor).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }
}
