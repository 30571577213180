export default class DarkMode {
  constructor() {
    this.button = document.querySelector('[data-dark-mode]');
    this.html = document.documentElement;
    this.store = localStorage;
  }

  static init() {
    return new DarkMode().init();
  }

  activateButton(boo) {
    this.button.checked = boo;
  }

  bindButton() {
    this.button.addEventListener('click', this.onChange.bind(this));
  }

  init() {
    if (this.isDark()) {
      this.setDark();
      this.activateButton(true);
    } else {
      this.setLight();
      this.activateButton(false);
    }

    this.bindButton();
  }

  isDark() {
    return this.store.getItem('theme') === 'dark';
  }

  onChange(evt) {
    evt.target.checked ? this.setDark() : this.setLight();
  }

  setDark() {
    this.html.dataset.theme = 'dark';

    this.store.setItem('theme', 'dark');
  }

  setLight() {
    this.html.dataset.theme = 'light';

    this.store.setItem('theme', 'light');
  }
}
