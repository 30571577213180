import GridyCommon from '@/modules/gridy_common';
import HandlebarsHelpers from '@/modules/handlebars_helpers';
import Local from '@/modules/local';

export default class Searcher {
  constructor(grid, template, options) {
    this.body = $('body');
    this.categorySelect = $('#gridy_where_category');
    this.filterButton = $('[data-searcher-filter-button]');
    this.grid = grid;
    this.listFilterButton = $('[data-list-filter-button]');
    this.options = options;
    this.rakingSorterField = $('#raking-sorter-field');
    this.rankingSorter = $('[ranking-sorter]');
    this.searcher = $('[data-searcher]');
    this.searcherContent = $('[data-searcher-content]');
    this.searcherField = $('[data-searcher-field]');
    this.switcher = $('[data-switcher]');
    this.template = template;
    this.typeSelect = $('#gridy_where_type');
  }

  bindCategorySelect() {
    this.categorySelect.on(
      'change',
      function () {
        this.grid.gridy('refresh');
      }.bind(this)
    );
  }

  bindRankingSorter() {
    var that = this;

    this.#addActiveClass(this.rankingSorter.find('[data-sort="total_of_rating"]'));

    this.rankingSorter.on('click', 'a', function () {
      var optionSelected = $(this);
      var sortField = optionSelected.data('sort');

      that.rakingSorterField.val(sortField);

      that.#activeSwitcherOption(optionSelected, sortField);

      that.grid.gridy('refresh');
    });
  }

  bindTypeSelect() {
    this.typeSelect.on(
      'change',
      function () {
        this.grid.gridy('refresh');
      }.bind(this)
    );
  }

  bindListFilter() {
    this.listFilterButton.on(
      'click',
      function () {
        this.searcherField.trigger('focus');
      }.bind(this)
    );
  }

  bindSearchFilter() {
    this.filterButton.on('click', this.closeModal.bind(this));

    this.searcherField.on(
      'keypress',
      function (evt) {
        var enterKey = 13;
        var key = evt.keyCode || evt.which;

        if (key === enterKey) {
          this.closeModal();
        }
      }.bind(this)
    );
  }

  bind() {
    this.bindCategorySelect();
    this.bindRankingSorter();
    this.bindTypeSelect();
    this.bindSearchFilter();
    this.modaly();
    this.bindListFilter();
  }

  create() {
    this.gridy();
    this.bind();

    new Local({ grid: this.grid }).create();

    HandlebarsHelpers.init();
  }

  gridy() {
    GridyCommon.gridy(this.grid, $.extend({}, this.gridyOptions(), this.options));
  }

  gridyOptions() {
    return {
      buttonMax: document.documentElement.clientWidth >= 667 ? 3 : 1,
      contentClass: 'card__grid card__grid--small gridy__content',
      find: 'name',
      findClass: 'form__input form__input--fluid mt-2.5',
      rows: 12,
      rowsClass: 'form__input form__input--fluid mt-2.5',
      rowsOption: false,
      searchButton: true,
      searchClass: 'form__input form__input--fluid mt-2.5',
      searchElement: '[data-searcher-field]',
      searchFocus: true,
      sortName: 'created_at',
      sortOrder: 'desc',
      statusOption: false,
      style: 'div',
      template: this.template,

      before: (search, page, sortName, sortOrder, elements) => {
        var category = $('#gridy_where_category option:not(:first):selected');
        var categoryName = category.length ? ' (' + category[0].text + ')' : '';
        var result = [];
        var wrapper = $('.list__resume');
        var typeField = $('#gridy_where_type');
        var typeIndex = typeField.length ? typeField[0].selectedIndex : 0;
        var typeLabel = typeField.length ? typeField[0].options[typeIndex].label : null;
        var typeTitle = (typeIndex === 0 ? null : typeLabel) || $('.searcher').data('title');
        var titleTerm = (search ? 'Resultado para: ' + search : typeTitle) + categoryName;
        var term = search || typeTitle;
        var uf = $('#gridy_where_uf option:selected');
        var city = $('#gridy_where_city option:selected');
        var neighborhood = $('#gridy_where_neighborhood option:selected');

        wrapper.find('[data-search-term]').text(titleTerm);

        for (var i = 0; i < elements.length; i++) {
          result += $(elements[i]).val();
        }

        uf = uf.val() === '' ? 'Todos os Estados' : uf.text();
        city = city.val() === '' ? 'Todas as Cidades' : city.text();
        neighborhood = neighborhood.val() === '' ? 'Todos os Bairros' : neighborhood.text();

        var cityUf = [city, uf].join(' - ');

        var text = [term, ['em', neighborhood].join(' '), cityUf].join(', ');

        wrapper.find('.list__resume__description').text(text);
      },

      paramsElements: [
        '#gridy_where_category',
        '#gridy_where_city',
        '#gridy_where_neighborhood',
        '#gridy_where_type',
        '#gridy_where_uf',
        '#raking-sorter-field',
      ],
    };
  }

  modaly() {
    this.listFilterButton.modaly();

    this.searcherContent.on(
      'modaly:closed',
      function () {
        this.body.removeClass('overflow-hidden');
      }.bind(this),
    );

    this.searcherContent.on(
      'modaly:opened',
      function () {
        this.body.addClass('overflow-hidden');
      }.bind(this),
    );
  }

  closeModal() {
    this.listFilterButton.modaly('close');
  }

  #addActiveClass(option) {
    option.addClass('switcher__option--active');
  }

  #cleanSwitcherOptions(sortOption, options) {
    for (var i = 0; i < options.length; i++) {
      var el = $(options[i]);

      if (el.data('sort') !== sortOption) {
        el.removeClass('switcher__option--active');
      }
    }
  }

  #activeSwitcherOption(switcherOption, sortOption) {
    var options = switcherOption.parent().parent().find('[data-switcher-option]');

    this.#cleanSwitcherOptions(sortOption, options);

    this.#addActiveClass(switcherOption);
  }
}
