export default class Voting {
  constructor(options) {
    this.wrapper = $(options.wrapper);
    this.dislikeButton = this.wrapper.find('.voting__dislike');
    this.likeButton = this.wrapper.find('.voting__like');
    this.options = options;
  }

  create() {
    this.binds();
  }

  binds() {
    const that = this;

    this.likeButton.on('click', 'a', function () {
      that.onClick(this);
    });

    if (this.dislikeButton.length) {
      this.dislikeButton.on('click', 'a', function () {
        that.onClick(this);
      });
    }
  }

  onClick(button) {
    const ajax = $.ajax({
      data: { value: button.getAttribute('data-value'), voteable_type: this.options.voteableType },
      type: 'PATCH',
      url: '/votes/' + button.getAttribute('data-voteable-id'),
    });

    ajax.done((json) => {
      let dislikeIconType = this.options.dislikeOffIcon;
      let likeIconType = this.options.likeOffIcon;

      const wrapper = $(button).closest(this.options.wrapper);

      if (json.status === 'positive') {
        likeIconType = this.options.likeOnIcon;
      } else if (json.status === 'negative') {
        dislikeIconType = this.options.dislikeOnIcon;
      }

      wrapper.find('.voting__like img').attr('src', likeIconType);
      wrapper.find('.voting__like span').text(json.positive);

      if (this.dislikeButton.length) {
        wrapper.find('.voting__dislike img').attr('src', dislikeIconType);
        wrapper.find('.voting__dislike span').text(json.negative);
      }
    });
  }
}
