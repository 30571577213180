{
  "pt-BR": {
    "action": {
      "other": "Ações"
    },
    "activerecord": {
      "attributes": {
        "editor": {
          "email": "E-mail",
          "promoter_role_id": "Perfil"
        },
        "ticket_order_item": {
          "checkin": "Check-in"
        },
        "user": {
          "email": "E-mail",
          "name": "Nome"
        },
        "withdraw": {
          "amount_cents": "Valor",
          "bank_agency": "Agência",
          "bank_kind": "Tipo",
          "bank_number": "Número",
          "message": "Mensagem",
          "paying_at": "Previsão",
          "status": "Status"
        }
      }
    },
    "approved": {
      "other": "Aprovados"
    },
    "dashboard": "Painel",
    "datepicker": {
      "format": "dd/MM/yy",
      "next_month": "Próximo mês",
      "placeholder": "__/__/__",
      "previous_month": "Mês anterior"
    },
    "disapproved": {
      "other": "Reprovados"
    },
    "edit": "Editar",
    "enrollment": {
      "one": "Matrícula"
    },
    "evaluate": "Avaliar",
    "finish": "Finalizar",
    "follow": {
      "one": "Conduzido",
      "other": "Conduzidos"
    },
    "graduation": {
      "profile": {
        "not_selected": "Perfil não selecionado"
      },
      "role": {
        "not_selected": "Função não selecionada"
      }
    },
    "gridy": {
      "errors": {
        "record_not_found": "Nenhum resultado."
      },
      "headers": {
        "abr": "Abr",
        "action": "Action",
        "actions": "Ações",
        "active": "Ativo",
        "ago": "Ago",
        "amount": "Valor",
        "artistic_name": "Nome Artístico",
        "birthdate": "Nascimento",
        "boleto_cents": "Boleto (R$)",
        "controller": "Controller",
        "course": "Curso",
        "created_at": "Data de criação",
        "credit_card_cents": "Crédito (R$)",
        "credit_card_percent": "Crédito (%)",
        "customer_name": "Cliente",
        "date": "Data",
        "days_before": "Dias antes",
        "default": "Padrão",
        "description": "Descrição",
        "dez": "Dez",
        "due_date": "Vence",
        "email": "E-mail",
        "end_at": "Fim",
        "evaluation_group": "Grupo",
        "exam": "Exame",
        "examiners": "Examinadoras",
        "featured": "Destaque",
        "fev": "Fev",
        "function": "Função",
        "grade": "Turma",
        "group": "Grupo",
        "highlight": "Destaque",
        "id": "ID",
        "interval": "Periodicidade",
        "invoice": "Fatura",
        "iugu_account_id": "Iugu Account ID",
        "iugu_api_token_production": "Iugu API Token (prod)",
        "iugu_user_token": "Iugu User Token",
        "jan": "Jan",
        "jul": "Jul",
        "jun": "Jun",
        "kind": "Tipo",
        "level": "Nível",
        "liquid": "Líquido",
        "mai": "Mai",
        "mar": "Mar",
        "menu": "Menu",
        "name": "Nome",
        "nov": "Nov",
        "out": "Out",
        "paid_at": "Pago em",
        "parent": "Pai",
        "percentage": "Porcentagem",
        "period": "Período",
        "pix_percent": "Pix (%)",
        "plan": {
          "name": "Plano"
        },
        "presentation": "Apresentação",
        "prices": "Preços",
        "quantity": "Quantidade",
        "result": "Resultado",
        "role": "Perfil",
        "school": "Escola",
        "set": "Set",
        "sold": "Vendido",
        "start_at": "Início",
        "status": "Status",
        "student": "Estudante",
        "tax": "Taxa",
        "tax_amount_input_cents": "Entrada ($)",
        "tax_amount_output_cents": "Saída ($)",
        "tax_percentage_input_cents": "Entrada (%)",
        "tax_percentage_output_cents": "Saída (%)",
        "teacher": "Professor",
        "teachers": "Professores",
        "ticket": "Ingresso",
        "total": "Total",
        "total_of_comments": "Comentários",
        "total_of_votes": "Likes",
        "trial_days": "Trial",
        "uid": "UID",
        "unit": {
          "name": "Unidade"
        },
        "units": "Unidade",
        "url": "URL",
        "user": {
          "email": "E-mail",
          "name": "Usuário"
        },
        "uuid": "UUID",
        "value": "Valor",
        "visible": "Visível"
      },
      "page": {
        "button": {
          "back": "< página anterior",
          "next": "próxima página >",
          "title": "Página"
        }
      },
      "refresh": {
        "title": "Recarregar"
      },
      "search": {
        "label": "Texto da consulta",
        "placeholder": "Digite aqui o que procura"
      },
      "status": {
        "full": "Página {from} de {to} | {total} registros",
        "one": "1 registro encontrado",
        "other": "{total} registros encontrados",
        "zero": ""
      }
    },
    "lead": {
      "one": "Condutor",
      "other": "Condutores"
    },
    "liquid": "Líquido",
    "mock": {
      "other": "Simulados"
    },
    "name": "Nome",
    "notification": {
      "other": "Notificações"
    },
    "notify": "Notificar",
    "payment_method": {
      "short": "Método"
    },
    "pending": {
      "other": "Pendentes"
    },
    "permission": {
      "other": "Permissões"
    },
    "profile": {
      "one": "Perfil"
    },
    "prospect": {
      "other": "Examinados"
    },
    "push_notification": {
      "confirm_question": "Para uma experiência completa, iremos te pedir permissão para notificações.",
      "denied_message": "Notificações desabilitada, para habilitar autorize no seu navegador.",
      "manual_revoking": "É necessário remover a permissão direto no seu navegador.",
      "status": {
        "default": "Habilite as notificações para novas funcionalidades.",
        "denied": "Notificações bloqueadas. Para habilitar configure seu navegador.",
        "granted": "Notificações ativa.",
        "unsupported": "Notificações não suportada neste dispositivo/navegador/versão."
      }
    },
    "rating": {
      "hints": {
        "basic": {
          "five": "Ótimo",
          "four": "Bom",
          "one": "Péssimo",
          "three": "Regular",
          "two": "Ruim"
        },
        "evaluation": {
          "four": "Ótimo",
          "one": "Motivo da reprovação",
          "three": "Bom",
          "two": "Treinar"
        }
      },
      "no_rated_msg": "Nenhuma avaliação ainda."
    },
    "result": {
      "other": "Resultados"
    },
    "role": {
      "one": "Perfil"
    },
    "show": "Visualizar",
    "student": {
      "one": "Aluno"
    },
    "success": "Sucesso",
    "tax": {
      "one": "Taxa"
    },
    "teacher": {
      "one": "Professor"
    },
    "today": "Hoje",
    "total": "Total",
    "wait": "Aguarde..."
  }
}