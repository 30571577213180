import modulejs from 'modulejs';

import Hearting from '@/modules/hearting';
import MenuActions from '@/modules/menu_actions';

modulejs.define('courses.show', () => {
  'use strict';

  function Module(options) {
    this.options = options;
  }

  Module.prototype = {
    create: function () {
      this.hearting();

      MenuActions.init();
    },

    hearting: function () {
      new Hearting({ voteableType: 'School', wrapper: '.profile__sidebar__body' }).init();
    },
  };

  return function (options) {
    return new Module(options);
  };
});
