import modulejs from 'modulejs';

import GridyCommon from '@/modules/gridy_common';
import I18n from '@/modules/i18n';

const Templates = require('@/pages/site/templates');

modulejs.define('withdraws.index', () => {
  'use strict';

  class WithdrawsIndex {
    constructor(options) {
      this.grid = $('[data-gridy]');
      this.options = options;
    }

    create() {
      this.gridy();
    }

    gridy() {
      GridyCommon.gridy(this.grid, {
        sortName: 'paying_at',
        template: Templates.withdraws.gridy,
        url: this.options.gridy.url,

        headers: [
          { name: I18n.t('activerecord.attributes.withdraw.paying_at'), value: 'paying_at' },
          { name: I18n.t('activerecord.attributes.withdraw.amount_cents'), value: 'amount_cents' },
          { name: I18n.t('activerecord.attributes.withdraw.status'), value: 'status' },
          { name: I18n.t('activerecord.attributes.withdraw.bank_agency'), value: 'bank_agency' },
          { name: I18n.t('activerecord.attributes.withdraw.bank_number'), value: 'bank_number' },
          { name: I18n.t('activerecord.attributes.withdraw.bank_kind'), value: 'bank_kind' },
          { name: I18n.t('activerecord.attributes.withdraw.message'), value: '' },
        ],
      });
    }
  }

  return WithdrawsIndex;
});
