import modulejs from 'modulejs';

import Commenter from '@/modules/commenter';
import Gallery from '@/modules/gallery';
import Hearting from '@/modules/hearting';
import MenuActions from '@/modules/menu_actions';
import Rating from '@/modules/rating';
import Voting from '@/modules/voting';

modulejs.define('teachers.show', () => {
  'use strict';

  class TeachersShow {
    constructor(options) {
      this.options = options;
    }

    voting() {
      new Voting(
        $.extend({}, this.options.voting.comment, {
          voteableType: 'Comment',
          wrapper: '.comment__actions',
        })
      ).create();
    }

    create() {
      new Commenter().create();

      this.voting();
      this.hearting();

      MenuActions.init();
      new Rating(this.options.rating).create();
      new Gallery().bindPhotos();
    }

    hearting() {
      new Hearting({ voteableType: 'Teacher', wrapper: '.profile__sidebar__body' }).init();
    }
  }

  return TeachersShow;
});
