import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

import { ajax } from '@/modules/ajax';
import { metaValue } from '@/modules/helper';

const appId = metaValue('firebase_public_app_id');
const projectId = metaValue('firebase_public_project_id');

const config = {
  apiKey: metaValue('firebase_public_api_key'),
  appId: appId,
  authDomain: `${projectId}.firebaseapp.com`,
  measurementId: metaValue('firebase_public_measurement_id'),
  messagingSenderId: metaValue('firebase_public_sender_id'),
  projectId: projectId,
  storageBucket: `${projectId}.appspot.com`,
};

const app = initializeApp(config);
const messaging = getMessaging(app);
const vapidKey = metaValue('firebase_public_vapid_key');

const fetchToken = async (registration) => {
  console.log('[firebase]', 'fetchToken...');

  try {
    const token = await getToken(messaging, { serviceWorkerRegistration: registration, vapidKey });

    console.log('[firebase]', 'fetchToken!');

    if (token) return token;

    console.log('[firebase]', 'fetchToken no result.');
  } catch (error) {
    console.log('[firebase]', 'fetchToken failed', error);

    throw error;
  }
};

const saveDevice = (token) => {
  console.log('[firebase]', 'saveDevice...');

  try {
    ajax({
      data: { device: { token, user_agent: navigator.userAgent } },
      method: 'POST',
      url: '/api/devices',
    });

    console.log('[firebase]', 'saveDevice!');
  } catch (error) {
    console.log('[firebase]', 'saveDevice failed', error);

    throw error;
  }
};

export const registerDevice = async () => {
  console.log('[firebase]', 'registerDevice...');

  try {
    const registration = await navigator.serviceWorker.ready;

    const token = await fetchToken(registration);

    if (token) saveDevice(token);

    console.log('[firebase]', 'registerDevice!');
  } catch (error) {
    console.log('[firebase]', 'registerDevice failed', error);

    throw error;
  }
};
