import modulejs from 'modulejs';

import Gallery from '@/modules/gallery';
import Tag from '@/modules/tag';
import Uploader from '@/modules/uploader';
import Zipcoder from '@/modules/zipcoder';

modulejs.define('teachers.form', () => {
  'use strict';

  class TeachersForm {
    constructor(options) {
      this.options = options;
    }

    create() {
      this.tag();

      Uploader.init();

      new Gallery().bindForm();
      new Zipcoder({ scope: 'personal' }).create();
    }

    tag() {
      new Tag({
        field: $('#complety__category'),
        keys: ['name'],
        name: 'teacher[category_ids][]',
        removeImage: this.options.tag.removeImage,
        template: 'categories',
        textAttribute: 'name',
        valueAttribute: 'id',
        wrapper: $('.tags'),
      }).create();
    }
  }

  return TeachersForm;
});
