import Helper from '@/modules/helper';

export default class Commenter {
  constructor(options) {
    this.options = options;
  }

  create() {
    this.scan();
    this.binds();
  }

  binds() {
    var that = this;

    that.comments.on('click', '.comment__reply', function () {
      var self = $(this),
        id = self.data('id'),
        name = self.data('name');

      that.noFeatured();
      that.setParent(id);
      that.write(name + ', ');
      that.showReplying(id, name);
      that.focuz();
    });

    that.replying.on('click', 'a', function (evt) {
      evt.preventDefault();

      var anchor = $(this).data('anchor');

      that.noFeatured();

      $(anchor).addClass('comment__card--active');

      Helper.scrollTo(anchor);
    });

    if (this.cancel) {
      this.cancel.addEventListener('click', () => {
        this.body.val('');
        this.parent.removeAttr('value');
        this.replying.removeClass('comment__replying-to--active');

        this.noFeatured();

        this.focuz();
      });
    }
  }

  focuz() {
    this.body.blur().focus();
  }

  noFeatured() {
    $('.comment__card').removeClass('comment__card--active');
  }

  scan() {
    this.body = $('#comment_body');
    this.comments = $('[data-comments]');
    this.parent = $('#comment_parent_id');
    this.cancel = document.querySelector('[data-comment-cancel]');
    this.replying = $('.comment__replying-to');
  }

  setParent(id) {
    this.parent.val(id);
  }

  showReplying(id, name) {
    var anchor = '#comment-' + id;

    this.replying
      .addClass('comment__replying-to--active')
      .find('strong')
      .html('<a href="' + anchor + '" data-anchor="' + anchor + '">' + name + '</a>');
  }

  write(text) {
    this.body.val(text);
  }
}
