export default class Local {
  constructor(options) {
    this.citySelect = $('#gridy_where_city, #city');
    this.neighborhoodSelect = $('#gridy_where_neighborhood, #neighborhood');
    this.options = $.extend({}, { count_param: 'total' }, options);
    this.ufSelect = $('#gridy_where_uf, #uf');
  }

  bindUfSelect() {
    var that = this;

    this.ufSelect.on('change', function () {
      if (that.neighborhoodSelect.length) {
        that.neighborhoodSelect.val('').attr('disabled', 'disabled');
      }

      that.citySelect.val('');

      if (this.value) {
        that.citySelect.removeAttr('disabled');
        that.populatesCities(this);
      } else {
        that.citySelect.attr('disabled', 'disabled');
      }

      if (that.options.grid) {
        that.options.grid.gridy('refresh');
      }
    });
  }

  bindCitySelect() {
    var that = this;

    this.citySelect.on('change', function () {
      that.neighborhoodSelect.val('');

      if (this.value) {
        that.populatesNeighborhoods(that.ufSelect[0], this);
        that.neighborhoodSelect.removeAttr('disabled');
      } else {
        that.neighborhoodSelect.attr('disabled', 'disabled');
      }

      if (that.options.grid) {
        that.options.grid.gridy('refresh');
      }
    });
  }

  bindNeighborhoodSelect() {
    this.neighborhoodSelect.on('change', () => {
      if (this.options.grid) {
        this.options.grid.gridy('refresh');
      }
    });
  }

  bind() {
    this.bindUfSelect();

    if (this.neighborhoodSelect.length) {
      this.bindCitySelect();
      this.bindNeighborhoodSelect();
    }
  }

  create() {
    this.bind();
  }

  populatesCities(ufSelect) {
    var count_param = this.options.count_param;
    var url = '/api/addresses/ufs/' + ufSelect.value + '/cities';
    var ajax = $.ajax({ data: { count_param: count_param }, url: url });

    ajax.done(
      function (json) {
        this.citySelect.html(this.#buildOptions(json.cities));
      }.bind(this)
    );
  }

  populatesNeighborhoods(ufSelect, citySelect) {
    var url = '/api/addresses/ufs/' + ufSelect.value + '/cities/' + citySelect.value + '/neighborhoods';
    var ajax = $.ajax({ url: url });

    ajax.done(
      function (json) {
        this.neighborhoodSelect.html(this.#buildOptions(json.neighborhoods));
      }.bind(this)
    );
  }

  // private

  #buildOptions(items) {
    var options = [];

    for (var i = 0; i < items.length; i++) {
      options += '<option value="' + items[i]['slug'] + '">' + items[i]['name'] + '</option>';
    }

    return options;
  }
}
