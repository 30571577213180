import modulejs from 'modulejs';

import Gallery from '@/modules/gallery';
import Phoner from '@/modules/phoner';
import Uploader from '@/modules/uploader';
import Zipcoder from '@/modules/zipcoder';

modulejs.define('units.form', () => {
  'use strict';

  class UnitsForm {
    create() {
      Uploader.init();

      Phoner.init({ nestedy: document.querySelector('[data-phones]') });

      $('#phones .nestedy').nestedy();

      new Gallery().bindForm();
      new Zipcoder({ scope: 'personal' }).create();
    }
  }

  return UnitsForm;
});
