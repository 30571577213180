import { ajax } from '@/modules/ajax';
import { loading } from '@/modules/helper';

export default class Autocomplete {
  constructor(options) {
    this.field = options.field;
    this.options = options;
    this.result = options.result;
    this.templates = options.templates;
  }

  afterSelected(json) {
    this.result?.html(this.templates.result(json)).show();
    this.field.trigger('show', json);
  }

  assigns() {
    this.complety = this.field.data('complety');
    this.prevent = this.options.prevent || [];

    this.wrappers = this.complety.wrappers();
  }

  binds() {
    if (this.prevent.indexOf('selected') < 0) {
      this.field.on('complety:selected', this.handleSelected.bind(this));
    }
  }

  complety() {
    this.field.complety(this.options.options);
  }

  create() {
    this.complety();
    this.assigns();
    this.binds();

    return this;
  }

  fail(json) {
    $.notify({
      body: json.status,
      details: json.responseText,
      title: json.statusText,
    });
  }

  handleSelected() {
    if (this.options.cleanField) this.field.val('');
    if (this.result) this.onSelected();
  }

  onSelected() {
    const data = {};
    const attributes = this.field.data();
    const url = this.field.data('selectUrl').replace(':id', attributes.value || this.field.val());

    for (let value in attributes) {
      if (/^completyParam.+/.test(value)) {
        data[value.replace('completyParam', '').toLowerCase()] = attributes[value];
      }
    }

    ajax({
      data: data,
      done: this.afterSelected.bind(this),
      loading: (bool) => loading(bool),
      url: url,
    });
  }
}
