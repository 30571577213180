import { Loader } from '@googlemaps/js-api-loader';

export default class Map {
  constructor(options) {
    this.container = document.querySelector('[data-map]');
    this.options = options;
  }

  create() {
    if (!this.container) return;

    this.getLocation();
  }

  async getLocation() {
    const loader = new Loader({ apiKey: this.options.apiKey });

    await loader.load();

    const { Map } = await google.maps.importLibrary('maps');

    const map = new Map(this.container, { center: { lat: 0, lng: 0 } });

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: this.options.address }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;

        map.setCenter(location);
        map.fitBounds(results[0].geometry.viewport);

        new google.maps.Marker({ fullscreenControl: true, map: map, position: location });
      }
    });
  }
}
