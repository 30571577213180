export default class Phones {
  constructor() {
    this.phoneButton = $('[data-phones-button]');
    this.phoneNumbers = $('[data-phones-number]');
  }

  bind() {
    var that = this;

    this.phoneButton.on('click', function () {
      that.hideButton();
      that.showPhonesList();
    });
  }

  create() {
    this.bind();
  }

  decrypt(bytes) {
    var result = '';

    for (var i = 0; i < bytes.length; i++) {
      result += String.fromCharCode(bytes[i]);
    }

    return result;
  }

  hideButton() {
    this.phoneButton.addClass('hidden');
  }

  showNumbers() {
    var that = this;

    this.phoneNumbers.each(function () {
      var phone = $(this);

      phone.text(that.decrypt(phone.data('phonesNumber').split(',')));
      phone.removeAttr('data-phones-number');
    });
  }

  showPhonesList() {
    this.showNumbers();

    this.phoneNumbers.removeClass('hidden');
  }
}
