// Extracted from tailwind.config.js
const screens = {
  'tb': 576,
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
  'dk': 1920,
};

export const isSm = () => window.innerWidth > screens.tb;
export const isMd = () => window.innerWidth > screens.sm;
