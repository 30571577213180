import { I18n } from 'i18n-js';

const locale = document.documentElement.lang;

// TODO: I think it'll still load all files not just the stored one on `i18n.store()`.
// In this case would be better export just on translations.json if it's not possible select the loaded file.
import en from '@/translations/en.json';
import ptBR from '@/translations/pt-BR.json';
import ru from '@/translations/ru.json';

const i18n = new I18n();

i18n.store(
  {
    'en': en,
    'pt-BR': ptBR,
    'ru': ru,
  }[locale]
);

i18n.enableFallback = true;
i18n.defaultLocale = document.documentElement.dataset.defaultLocale;
i18n.locale = locale;

export default i18n;
