import modulejs from 'modulejs';

import Checkout from '@/modules/checkout';
import BoletoWatcher from '@/modules/boleto_watcher';

modulejs.define('invoices.show', () => {
  'use strict';

  class InvoicesShow {
    constructor(options) {
      this.options = options;
    }

    create() {
      new Checkout(this.options).create();
      new BoletoWatcher(this.options).create();
    }
  }

  return InvoicesShow;
});
