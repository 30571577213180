import clock from '@/modules/clock';

export default class GradeTree {
  constructor() {
    this.daysOfweek = clock.weekdays();
  }

  activateTab(event) {
    const options = this.currentTabOptions(event);

    $('[data-grade-tree-content-day="' + options.day + '"]').addClass('grade-tree__content--active');
    $(options.target).addClass('grade-tree__button--active');
  }

  bind() {
    for (let item = 0; item < this.daysOfweek.length; item++) {
      const day = this.daysOfweek[item];

      $('[data-grade-tree-button-day="' + day.toLowerCase() + '"]').on('click', this.onDayClick.bind(this));
    }
  }

  create() {
    this.bind();

    this.activateTab();
  }

  currentTabOptions(event) {
    if (event) {
      return { target: event.currentTarget, day: event.currentTarget.dataset.gradeTreeButtonDay };
    }

    const firstActivated = $('.grade-tree__button:not(".grade-tree__button--disabled"):first');
    const initialDay = firstActivated.data('gradeTreeButtonDay');

    return { target: '[data-grade-tree-button-day="' + initialDay + '"]', day: initialDay };
  }

  desactivateTabs() {
    $('[data-grade-tree-content]').removeClass('grade-tree__content--active');
    $('[data-grade-tree-button]').removeClass('grade-tree__button--active');
  }

  onDayClick(event) {
    this.desactivateTabs();
    this.activateTab(event);
  }
}
