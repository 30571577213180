import Masker from '@/modules/masker';

export default class Phoner {
  static init(options = {}) {
    const selector = options.selector || '[data-phone]';
    const fields = options.field ? [options.field] : document.querySelectorAll(selector);

    for (let i = 0; i < fields.length; i++) {
      Masker.phone(fields[i]);
    }

    if (options.nestedy) {
      this.#nestedy(options.nestedy, selector);
    }
  }

  static #nestedy(wrapper, selector) {
    $(wrapper).nestedy({
      clone: false,
      destroy: true,

      clear: function (item) {
        return this.type !== 'hidden';
      },

      excludes: function () {
        return /_phones_attributes_\d_id/.test(this.id) || this.className === 'message--error';
      },

      onAdd: (item) => {
        Masker.phone(item.querySelector(selector));
      },
    });
  }
}
