export default class SearchBar {
  constructor() {
    this.searchbar = $('.searchbar');
    this.buttonClass = '.searchbar--inactive';
    this.menuClass = '.navbar__nav';
    this.inputClass = '.searchbar__input';
    this.closeClass = '.searchbar__close';
  }

  aboveMobile() {
    return window.innerWidth > 960;
  }

  bind() {
    $(document).on('click', this.buttonClass, this.open.bind(this));
    $(document).on('click', this.closeClass, this.close.bind(this));
  }

  close() {
    this.toggleSearch();
    this.toggleMenu();
    this.searchbar.addClass('searchbar--inactive');
  }

  create() {
    this.bind();
  }

  focus() {
    $(this.inputClass).focus();
  }

  open() {
    this.toggleSearch();
    this.toggleMenu();
    this.focus();
    this.searchbar.removeClass('searchbar--inactive');
  }

  toggleMenu() {
    if (this.aboveMobile()) {
      $(this.menuClass).fadeToggle(200);
    }
  }

  toggleSearch() {
    this.searchbar.toggleClass('searchbar--active');
  }
}
