export default class Switcher {
  constructor(options) {
    this.options = options;
    this.switcherContent = $('.switcher__content:not(.switcher--ignore)');
    this.switcherOptions = $('.switcher__option');
    this.switcherValue = document.querySelector('[data-switcher-value]');
  }

  bind() {
    this.switcherOptions.on('click', (evt) => {
      this.switch(evt);
    });
  }

  create() {
    this.bind();

    if (this.switcherContent.length && this.switcherOptions.length) {
      this.firstState();
    }
  }

  firstState() {
    if (this.switcherValue) {
      const value = this.switcherValue.value;

      this.switchOption(value);
      this.switchContent(value);
    }
  }

  switch(evt) {
    this.clearAll();
    this.switchOption(evt.target.dataset.option);
    this.switchContent(evt.currentTarget.dataset.option);
  }

  switchContent(option) {
    const element =
      document.querySelector(`.switcher__content[data-content=${option}]`) ||
      document.querySelector('.switcher__content[data-content]');

    element.classList.add('switcher__content--active');

    if (this.options.callback) {
      this.options.callback.call(this, option);
    }
  }

  switchOption(option) {
    const element =
      document.querySelector(`.switcher__option[data-option=${option}]`) ||
      document.querySelector('.switcher__option[data-option]');

    element.classList.add('switcher__option--active');
  }

  clearAll() {
    this.switcherOptions.removeClass('switcher__option--active');
    this.switcherContent.removeClass('switcher__content--active');
  }
}
