import modulejs from 'modulejs';

import Checkout from '@/modules/checkout';
import Product from '@/modules/product';

modulejs.define('units.checkout', () => {
  'use strict';

  class UnitCheckout {
    constructor(options) {
      this.options = options;
    }

    create() {
      new Product().create();
      new Checkout(this.options).create();
    }
  }

  return UnitCheckout;
});
