import modulejs from 'modulejs';

import Masker from '@/modules/masker';

modulejs.define('payment_accounts.show', () => {
  'use strict';

  class PaymentAccountsShow {
    constructor() {
      this.amountField = document.querySelector('[data-money]');
    }

    create() {
      Masker.money(this.amountField);
    }
  }

  return PaymentAccountsShow;
});
