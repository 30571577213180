import modulejs from 'modulejs';

import Hearting from '@/modules/hearting';
import Map from '@/modules/map';
import MenuActions from '@/modules/menu_actions';
import Phones from '@/modules/phones';
import Rating from '@/modules/rating';
import Voting from '@/modules/voting';

modulejs.define('courses.show_unit', () => {
  'use strict';

  function Module(options) {
    this.options = options;
  }

  Module.prototype = {
    create: function () {
      this.hearting();

      MenuActions.init();
      new Phones().create();
      new Rating(this.options.rating).create();
      new Map(this.options.map).create();
    },

    hearting: function () {
      new Hearting({ voteableType: 'Unit', wrapper: '.profile__sidebar__body' }).init();
    },
  };

  return function (options) {
    return new Module(options);
  };
});
