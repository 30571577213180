import * as ActiveStorage from '@rails/activestorage';

ActiveStorage.start();

// GLOBALS

import '@/vendor/handlebars';
import '@/vendor/jquery';
import '@/vendor/rails';

import '@/vendor/complety';
import '@/vendor/jquery.gridy';
import '@/vendor/jquery.magnific-popup';
import '@/vendor/jquery.maskMoney';
import '@/vendor/jquery.modaly';
import '@/vendor/jquery.nestedy';
import '@/vendor/jquery.notify';
import '@/vendor/jquery.validaty';
import '@/vendor/jquery.validaty.validators'; // after: @/vendor/jquery.validaty

// PAGES

import '@/pages/site/views/categories/show';
import '@/pages/site/views/courses/show_unit';
import '@/pages/site/views/courses/show';
import '@/pages/site/views/credit_cards/new';
import '@/pages/site/views/invoices/show';
import '@/pages/site/views/link_trees/form';
import '@/pages/site/views/link_trees/index';
import '@/pages/site/views/payment_accounts/form';
import '@/pages/site/views/payment_accounts/show';
import '@/pages/site/views/products/show';
import '@/pages/site/views/schools/form';
import '@/pages/site/views/schools/show';
import '@/pages/site/views/searches/index';
import '@/pages/site/views/settings/form';
import '@/pages/site/views/teachers/form';
import '@/pages/site/views/teachers/index';
import '@/pages/site/views/teachers/show';
import '@/pages/site/views/units/checkout';
import '@/pages/site/views/units/form';
import '@/pages/site/views/units/index';
import '@/pages/site/views/units/show';
import '@/pages/site/views/users/show';
import '@/pages/site/views/withdraws/index';

// ON READY

import boot from '@/pages/site/boot';
import onReady from '@/modules/on_ready';

// RUN

onReady(boot);
