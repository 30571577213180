export default class Gallery {
  bindForm() {
    this.bindPhotos('.photos__form__link');

    $('[data-remove-image]').on('click', function () {
      var ajax = $.ajax({
        type: 'DELETE',
        url: '/site/images/' + this.getAttribute('data-image-id'),
      });

      ajax.done((json) => {
        $('[data-image-id="' + json.id + '"]')
          .parent()
          .fadeOut('slow', () => {
            this.remove();
          });
      });
    });
  }

  bindPhotos(selector) {
    var wrapper = $('.photos');
    var options = { delegate: selector || '.photos__link' };

    var defaults = {
      tClose: 'Fechar (Esc)',
      tLoading: 'Carregando imagem #%curr%...',
      type: 'image',

      gallery: {
        tCounter: '%curr% de %total%',
        tNext: 'Próxima (seta direita do teclado)',
        tPrev: 'Anterior (seta esquerda do teclado)',
      },

      image: {
        tError: '<a href="%url%">A imagem #%curr%</a> não pode ser carregada.',
      },
    };

    if (options.delegate === '.photos__link' || options.delegate === '.photos__form__link') {
      defaults.gallery.enabled = true;
      defaults.gallery.navigateByImgClick = true;
      defaults.gallery.preload = [0, 1];
    }

    return wrapper.magnificPopup($.extend(true, {}, defaults, options));
  }
}
