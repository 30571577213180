import Masker from '@/modules/masker';
import Zipcoder from '@/modules/zipcoder';

export default class PaymentAccount {
  constructor() {
    this.moneyFields = document.querySelectorAll('[data-money]');
    this.numberFields = document.querySelectorAll('[data-number]');

    this.opositeWrappers = {
      cnpj: document.querySelector('[data-person-type-fields="cpf"]'),
      cpf: document.querySelector('[data-person-type-fields="cnpj"]'),
    };

    this.percentageFields = document.querySelectorAll('[data-percentage]');
    this.personTypes = document.querySelectorAll('[data-person-type]');

    this.wrappers = {
      cnpj: document.querySelector('[data-person-type-fields="cnpj"]'),
      cpf: document.querySelector('[data-person-type-fields="cpf"]'),
    };
  }

  bindPersonType() {
    for (const input of this.personTypes) {
      input.addEventListener('change', (evt) => {
        this.#showPersonType(evt.currentTarget.value);
      });
    }

    this.#showPersonType(document.querySelector('[data-person-type]:checked').value);
  }

  init() {
    new Zipcoder({ scope: 'account' }).create();

    this.bindPersonType();
    this.mask();
  }

  mask() {
    for (const input of this.numberFields) {
      Masker.number(input, { max: 10 });
    }

    Masker.phone(document.querySelector('[data-phone]'));
    Masker.cpf(document.querySelector('[data-cpf]'));
    Masker.cnpj(document.querySelector('[data-cnpj]'));

    for (const input of this.moneyFields) {
      Masker.money(input);
    }

    for (const input of this.percentageFields) {
      Masker.percentage(input);
    }
  }

  #showPersonType(value) {
    this.wrappers[value].classList.remove('hidden');
    this.opositeWrappers[value].classList.add('hidden');
  }
}
