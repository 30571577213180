import modulejs from 'modulejs';

import Local from '@/modules/local';
import Searcher from '@/modules/searcher';

const Templates = require('@/pages/site/templates');

modulejs.define('teachers.index', () => {
  'use strict';

  function Module(options) {
    this.grid = $('[data-gridy]');
    this.options = options;
  }

  Module.prototype = {
    create: function () {
      new Searcher(this.grid, Templates.teachers.gridy, this.options.gridy).create();
      new Local().create();

      return this;
    },
  };

  return Module;
});
