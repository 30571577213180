{
  "en": {
    "action": {
      "other": "Actions"
    },
    "activerecord": {
      "attributes": {
        "editor": {
          "email": "Email",
          "promoter_role_id": "Role"
        },
        "ticket_order_item": {
          "checkin": "Check-in"
        },
        "user": {
          "email": "Email",
          "name": "Name"
        },
        "withdraw": {
          "amount_cents": "Value",
          "bank_agency": "Agency",
          "bank_kind": "Type",
          "bank_number": "Number",
          "message": "Message",
          "paying_at": "Forecast",
          "status": "Status"
        }
      }
    },
    "approved": {
      "other": "Approved"
    },
    "dashboard": "Dashboard",
    "datepicker": {
      "format": "dd/MM/yy",
      "next_month": "Next month",
      "placeholder": "__/__/__",
      "previous_month": "Last month"
    },
    "disapproved": {
      "other": "Failed"
    },
    "edit": "Edit",
    "enrollment": {
      "one": "Enrollment"
    },
    "evaluate": "Evaluate",
    "finish": "Finish",
    "follow": {
      "one": "Follower",
      "other": "Followers"
    },
    "graduation": {
      "profile": {
        "not_selected": "Profile not selected"
      },
      "role": {
        "not_selected": "Role not selected"
      }
    },
    "gridy": {
      "errors": {
        "record_not_found": "No results."
      },
      "headers": {
        "abr": "Apr",
        "action": "action",
        "actions": "Actions",
        "active": "active",
        "ago": "Aug",
        "amount": "Value",
        "artistic_name": "Artistic Name",
        "birthdate": "Birth",
        "boleto_cents": "Boleto ($)",
        "controller": "Controller",
        "course": "Course",
        "created_at": "Creation date",
        "credit_card_cents": "Credit ($)",
        "credit_card_percent": "Credit (%)",
        "customer_name": "Client",
        "date": "Date",
        "days_before": "days before",
        "default": "Pattern",
        "description": "Description",
        "dez": "Ten",
        "due_date": "Due Date",
        "email": "Email",
        "end_at": "End",
        "evaluation_group": "group",
        "exam": "Exam",
        "examiners": "Examiners",
        "featured": "Emphasis",
        "fev": "Feb",
        "function": "Occupation",
        "grade": "Gang",
        "group": "group",
        "highlight": "Emphasis",
        "id": "ID",
        "interval": "Frequency",
        "invoice": "Invoice",
        "iugu_account_id": "Iugu Account ID",
        "iugu_api_token_production": "Iugu API Token (prod)",
        "iugu_user_token": "Iugu UserToken",
        "jan": "Jan",
        "jul": "Jul",
        "jun": "June",
        "kind": "Type",
        "level": "Level",
        "liquid": "liquid",
        "mai": "May",
        "mar": "Mar",
        "menu": "Menu",
        "name": "Name",
        "nov": "Nov",
        "out": "Oct",
        "paid_at": "Paid at",
        "parent": "Parent",
        "percentage": "percentage",
        "period": "Period",
        "pix_percent": "Pix (%)",
        "plan": {
          "name": "Plan"
        },
        "presentation": "Presentation",
        "prices": "Prices",
        "quantity": "The amount",
        "result": "Result",
        "role": "Profile",
        "school": "School",
        "set": "Sep",
        "sold": "sold",
        "start_at": "Start",
        "status": "Status",
        "student": "Student",
        "tax": "Rate",
        "tax_amount_input_cents": "Input ($)",
        "tax_amount_output_cents": "Output ($)",
        "tax_percentage_input_cents": "Input (%)",
        "tax_percentage_output_cents": "Output (%)",
        "teacher": "Teacher",
        "teachers": "teachers",
        "ticket": "Ticket",
        "total": "Total",
        "total_of_comments": "Comments",
        "total_of_votes": "likes",
        "trial_days": "Trial",
        "uid": "UID",
        "unit": {
          "name": "Unit"
        },
        "units": "Unit",
        "url": "URL",
        "user": {
          "email": "Email",
          "name": "User"
        },
        "uuid": "UUID",
        "value": "Value",
        "visible": "visible"
      },
      "page": {
        "button": {
          "back": "< previous page",
          "next": "next page >",
          "title": "Page"
        }
      },
      "refresh": {
        "title": "reload"
      },
      "search": {
        "label": "Query text",
        "placeholder": "Type here what you are looking for"
      },
      "status": {
        "full": "Page {from} of {to} | {total} records",
        "one": "1 record found",
        "other": "{total} records found",
        "zero": ""
      }
    },
    "lead": {
      "one": "Leader",
      "other": "Leaders"
    },
    "liquid": "Liquid",
    "mock": {
      "other": "Mock"
    },
    "name": "Name",
    "notification": {
      "other": "Notifications"
    },
    "notify": "Notify",
    "payment_method": {
      "short": "Method"
    },
    "pending": {
      "other": "Pending"
    },
    "permission": {
      "other": "Permissions"
    },
    "profile": {
      "one": "Profile"
    },
    "prospect": {
      "other": "Prospects"
    },
    "push_notification": {
      "confirm_question": "For a full experience, we will ask you for permission for notifications.",
      "denied_message": "Notifications disabled, to enable authorize in your browser.",
      "manual_revoking": "You need to remove permission directly from your browser.",
      "status": {
        "default": "Enable notifications for new features.",
        "denied": "Notifications blocked. To enable, configure your browser.",
        "granted": "Activate notifications.",
        "unsupported": "Notifications not supported on this device/browser/version."
      }
    },
    "rating": {
      "hints": {
        "basic": {
          "five": "Excellent",
          "four": "Good",
          "one": "Terrible",
          "three": "Regular",
          "two": "Bad"
        },
        "evaluation": {
          "four": "Excellent",
          "one": "reason for failure",
          "three": "Good",
          "two": "To train"
        }
      },
      "no_rated_msg": "No reviews yet."
    },
    "result": {
      "other": "Results"
    },
    "role": {
      "one": "Profile"
    },
    "show": "Show",
    "student": {
      "one": "Student"
    },
    "success": "Success",
    "tax": {
      "one": "Tax"
    },
    "teacher": {
      "one": "Teacher"
    },
    "today": "Today",
    "total": "Total",
    "wait": "Wait..."
  }
}