import { isValid as isValidCpf } from '@fnando/cpf';

$.validaty

  .register('cpf', 'CPF inválido!', function (helper, el) {
    'use strict';

    return this.val() === '' || isValidCpf(this.val());
  })

  .register('email', 'Esse e-mail não parece válido', function (helper, el) {
    'use strict';

    const value = this.val().trim();

    return value === '' || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  })

  .register(
    'required',
    {
      checkbox: 'Faltou selecionar aqui',
      email: 'Faltou preencher aqui',
      number: 'Faltou preencher aqui',
      radio: 'Faltou selecionar aqui',
      select: 'Faltou escolher aqui',
      tel: 'Faltou preencher aqui',
      text: 'Faltou preencher aqui',
      textarea: 'Faltou preencher aqui',
    },
    function (helper, el) {
      'use strict';

      if (helper.isCheckable(this[0])) {
        const attributes = '[name="' + this.attr('name') + '"]:checked';

        return $(el).find(':radio' + attributes + ', :checkbox' + attributes).length > 0;
      } else {
        return this.val().trim() !== '';
      }
    },
  )

  .register('credit-card-number', 'Número inválido!', function (helper, el) {
    'use strict';

    const value = this.val().trim();

    return Iugu.utils.validateCreditCardNumber(value);
  })

  .register('credit-card-cvv', 'Número inválido!', function (helper, el) {
    'use strict';

    const number = $(el).find('[data-card-number-field]');
    const flag = Iugu.utils.getBrandByCreditCardNumber(number.val());
    const value = this.val().trim();

    return Iugu.utils.validateCVV(value, flag);
  })

  .register('credit-card-expiration', 'Use mês/ano (mm/aa)!', function (helper, el) {
    'use strict';

    const value = this.val().trim();

    return Iugu.utils.validateExpirationString(value);
  })

  .register('min_checked', { checkbox: 'Faltou selecionar aqui' }, function (helper, el) {
    'use strict';

    const attributes = `[data-group="${this[0].dataset.group}"]:checked`;

    return $(el).find(':checkbox' + attributes).length > 0;
  });
