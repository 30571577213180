import dayjs from 'dayjs';

import Helper from '@/modules/helper';
import IMask from 'imask';

export default class Masker {
  static cnpj(el) {
    this.#mask(el, { mask: '00.000.000/0000-00' });
  }

  static cpf(el) {
    this.#mask(el, { mask: '000.000.000-00' });
  }

  static cardExpiration(el) {
    return this.date(el, { pattern: 'm{/}YY' });
  }

  static cardCVV(el) {
    return this.number(el, { max: 4, min: 3 });
  }

  static cardNumber(el) {
    return this.number(el, { max: 16, min: 15 });
  }

  static date(el, options = {}) {
    this.#mask(el, {
      blocks: {
        d: {
          from: 1,
          mask: IMask.MaskedRange,
          maxLength: 2,
          to: 31,
        },

        m: {
          from: 1,
          mask: IMask.MaskedRange,
          maxLength: 2,
          to: 12,
        },

        Y: {
          from: 0,
          mask: IMask.MaskedRange,
          maxLength: 2,
          to: 99,
        },
      },

      format(date) {
        return dayjs(date).format(Helper.dateFormat());
      },

      mask: Date,

      parse(str) {
        return dayjs(str, Helper.dateFormat());
      },

      pattern: options.pattern || 'd{/}m{/}YY',
      lazy: true,
    });
  }

  static letter(el, options) {
    const charMask = 'a';
    const defaults = { lazy: true };
    const max = options.max || 140;
    const min = options.min || 1;
    const settings = { ...defaults, ...options };

    this.#repeated(el, settings, charMask, min, max);
  }

  static money(el, options = {}) {
    const $el = $(el);

    let callMask = false;

    if (options.mask) {
      callMask = delete options.mask;
    }

    const opt = $.extend(
      {},
      {
        allowZero: true,
        decimal: ',',
        prefix: `${Helper.currencySymbol()} `,
        thousands: '.',
      },
      options
    );

    $el.maskMoney(opt);

    if (callMask) {
      $el.maskMoney('mask');
    }
  }

  static number(el, options = {}) {
    const charMask = '0';
    const defaults = { lazy: true };
    const max = options.max || 5;
    const min = options.min || 1;
    const settings = { ...defaults, ...options };

    this.#repeated(el, settings, charMask, min, max);
  }

  static percentage(el) {
    const $el = $(el);

    const options = {
      allowZero: true,
      decimal: ',',
      suffix: ` %`,
      thousands: '.',
    };

    let callMask = false;

    if (options.mask) {
      callMask = delete options.mask;
    }

    $el.maskMoney(options);

    if (callMask) {
      $el.maskMoney('mask');
    }
  }

  static phone(el) {
    this.#mask(el, { mask: [{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }] });
  }

  static state(el, options = {}) {
    const defaults = { max: 2, min: 2 };
    const settings = { ...defaults, ...options };

    return this.letter(el, settings);
  }

  static time(el) {
    this.#mask(el, {
      definitions: {
        H: /[0-2]/,
        h: /[0-9]/,
        M: /[0-5]/,
        m: /[0-9]/,
      },
      lazy: true,
      mask: 'Hh:Mm',
    });
  }

  static zipCode(el) {
    this.#mask(el, { lazy: true, mask: '00000-000' }); // lazy: true prevents typing
  }

  static #mask(el, options) {
    IMask(el, options);
  }

  static #repeated(el, options, charMask, min, max) {
    const totalOptionalsChars = max - min;

    let mask = '';

    for (let i = 0; i < min; i++) {
      mask += charMask;
    }

    for (let i = 0; i < totalOptionalsChars; i++) {
      mask += `[${charMask}]`;
    }

    options.mask = mask;

    this.#mask(el, options);
  }
}
