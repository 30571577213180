import HandlebarsHelpers from '@/modules/handlebars_helpers';
import I18n from '@/modules/i18n';

export default class GridyCommon {
  // The `sortName` is not specified here because it depends of which column you want, so can't be default.
  static fillCsvData(params) {
    const common = [
      () => {
        return { name: 'search', value: document.querySelector('[data-gridy-search-field]').value };
      },

      () => {
        return { name: 'sortOrder', value: document.querySelector('.gridy__sorter--sorted')?.rel || 'desc' };
      },
    ];

    common.concat(params).forEach((callback) => {
      if (typeof callback !== 'function') return;

      const data = callback();

      if (!data) {
        return;
      }

      let name = data.name.replace(/(.+?)\[/, 'gridy[$1][');

      if (name === data.name) {
        name = `gridy[${name}]`;
      }

      document.querySelector(`[data-param-csv][name='${name}']`).value = data.value;
    });
  }

  static gridy(el, options) {
    const defaults = {
      buttonBackTitle: I18n.t('gridy.page.button.back'),
      buttonNextTitle: I18n.t('gridy.page.button.next'),
      contentClass: 'gridy__content table-auto',
      evenOdd: false,
      loadingOption: false,
      loadingText: I18n.t('gridy.loading'),
      noResultText: I18n.t('gridy.errors.record_not_found'),
      pageButtonTitle: I18n.t('gridy.page.button.title'),
      refreshOption: false,
      rows: 50,
      rowsOption: false,
      searchButtonTitle: I18n.t('gridy.search.title'),
      searchElement: '[data-gridy-search-field]',
      searchPlaceholder: I18n.t('gridy.search.text'),

      statusText: {
        full: I18n.t('gridy.status.full'),
        one: I18n.t('gridy.status.one'),
        other: I18n.t('gridy.status.other'),
        zero: I18n.t('gridy.status.zero'),
      },
    };

    HandlebarsHelpers.init();

    return el.gridy($.extend(true, {}, defaults, options));
  }
}
