import modulejs from 'modulejs';

import Gallery from '@/modules/gallery';
import Uploader from '@/modules/uploader';

modulejs.define('schools.form', () => {
  'use strict';

  class SchoolsForm {
    create() {
      Uploader.init();

      new Gallery().bindForm();
    }
  }

  return SchoolsForm;
});
