import tippy from 'tippy.js';

export default class Tip {
  static init(options = {}) {
    const selector = options.selector || '[data-tip]';

    delete options.selector;

    const defaultOptions = {
      animation: 'shift-away',
      content: (element) => {
        return element.getAttribute(selector.replace('[', '').replace(']', ''));
      },
      delay: [300, 0],
    };

    tippy(selector, { ...defaultOptions, ...options });
  }
}
