import ClipboardJS from 'clipboard';
import I18n from '@/modules/i18n';

export default class BoletoWatcher {
  constructor(options) {
    this.boletoLoading = $('[data-boleto-loading]');
    this.boletoStatus = $('[data-boleto-status]');
    this.options = options;
  }

  bind() {
    const clipboard = new ClipboardJS('[data-copy-button]');

    clipboard.on('success', (e) => {
      const button = e.trigger;
      const originalText = button.innerHTML;

      button.innerHTML = `${I18n.t('success')}!`;

      setTimeout(() => {
        button.innerHTML = originalText;
      }, 2000);

      e.clearSelection();
    });

    clipboard.on('error', (e) => {
      const button = e.trigger;
      const originalText = button.innerHTML;

      button.innerHTML = 'Falha...';

      setTimeout(() => {
        button.innerHTML = originalText;
      }, 2000);
    });
  }

  create() {
    this.bind();

    if (this.boletoLoading.length) {
      this.watch();
    }
  }

  check() {
    $.ajax(this.options.invoice_url).done(this.onCheck.bind(this));
  }

  onCheck(json) {
    console.log(`[onCheck] ${json.payment_method} -> ${json.original_boleto_status}`);

    this.boletoStatus.text(json.boleto_status);

    if (json.original_boleto_status == 'boleto_registered') {
      this.boletoLoading.remove();
      this.boletoStatus.removeClass('bg-redy').addClass('bg-greeny');

      clearInterval(this.intervalCheck);
    }
  }

  watch() {
    this.intervalCheck = setInterval(this.check.bind(this), 3000);
  }
}
