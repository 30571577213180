import Masker from '@/modules/masker';

export default class CreditCard {
  constructor(options) {
    this.cardCVV = document.querySelector('[data-card-cvv-field]');
    this.cardExpirationDate = document.querySelector('[data-card-expiration-date-field]');
    this.cardHolderName = document.querySelector('[data-card-holder-name-field]');
    this.cardNumber = document.querySelector('[data-card-number-field]');
    this.options = options;
    this.token = document.querySelector('[data-token]');
  }

  create() {
    if (this.cardCVV) {
      Masker.cardCVV(this.cardCVV);
      Masker.cardExpiration(this.cardExpirationDate);
      Masker.cardNumber(this.cardNumber);
    }

    return this;
  }

  createToken() {
    Iugu.createPaymentToken(this.creditCard(), this.onCreateToken.bind(this));
  }

  creditCard() {
    const number = this.cardNumber.value;
    const monthExpiration = this.cardExpirationDate.value.split('/')[0];
    const yearExpiration = this.cardExpirationDate.value.split('/')[1];
    const firstHolderName = this.cardHolderName.value.split(' ')[0];
    const lastHolderName = this.cardHolderName.value.split(' ').pop();
    const cvv = this.cardCVV.value;

    return Iugu.CreditCard(number, monthExpiration, yearExpiration, firstHolderName, lastHolderName, cvv);
  }

  onCreateToken(json) {
    if (json.errors) {
      $.notify({
        details: JSON.stringify(json.errors),
        title: 'Não foi possível gerar o token do cartão de crédito.',
      });

      this.options.fail(json.errors);

      return;
    }

    this.token.value = json.id;

    this.options.success(json);
  }
}
