import { ajaxHandle } from '@/modules/ajax_handler';
import { loading } from '@/modules/helper';

const always = (json, _xhr, options) => {
  options.loading ? options.loading(false) : loading(false);

  if (options.always) options.always(json);
};

const done = (json, xhr, options) => {
  ajaxHandle(xhr);

  if (options.done) options.done(json);
};

const fail = (json, xhr, options) => {
  ajaxHandle(xhr);

  if (options.fail) options.fail(json);
};

export const ajax = (options) => {
  options.loading ? options.loading(true) : loading(true);

  if (options.params) options.data = options.params;

  $.ajax(options)
    .done((json, _statusText, xhr) => done(json, xhr, options))
    .fail((json, _statusText, xhr) => fail(json, xhr, options))
    .always((json, _statusText, xhr) => always(json, xhr, options));
};
