import modulejs from 'modulejs';

import PaymentMethod from '@/modules/payment_method';

modulejs.define('credit_cards.new', () => {
  'use strict';

  class CreditCardsNew {
    constructor(options) {
      this.options = options;
    }

    create() {
      new PaymentMethod(this.options).create();
    }
  }

  return CreditCardsNew;
});
