export const concat = (array1, array2) => [].concat(array1, array2);

export const enableScroll = (bool) => {
  const classList = document.body.classList;

  bool ? classList.remove('overflow-hidden') : classList.add('overflow-hidden');
};

export const hide = (el) => {
  if (NodeList.prototype.isPrototypeOf(el)) {
    for (const item of el) {
      item.classList.add('hidden');
    }
  } else {
    el.classList.add('hidden');
  }
};

export const isEmpty = (value) => Object.keys(value).length === 0;

export const isIOS = () => document.querySelector('[data-param][name="ios"]').value === 'true';

export const isNotificationSupported = () => 'Notification' in window;

export const loading = (bool) => {
  const classList = document.querySelector('[data-global-loading]').classList;

  bool ? classList.remove('hidden') : classList.add('hidden');
};

export const metaValue = (key) => document.head.querySelector(`meta[name='${key}']`)?.content;

export const show = (el) => {
  if (NodeList.prototype.isPrototypeOf(el)) {
    for (const item of el) {
      item.classList.remove('hidden');
    }
  } else {
    el.classList.remove('hidden');
  }
};

export const redirectTo = (url, query) => {
  window.location.href = query ? `${url}?${query}` : url;
};

export default class Helper {
  static boldAnimate(el) {
    if (!el.length) {
      return;
    }

    el.css('font-weight', 'bold');

    setTimeout(function () {
      el.css('font-weight', '');
    }, 2000);
  }

  static closestData(el, selector) {
    while (el.parentNode) {
      el = el.parentNode;

      if (selector in el.dataset) {
        return el;
      }
    }

    return null;
  }

  static commonParams(params = {}) {
    const csrf_token = document.querySelector('meta[name="csrf-token"]');

    if (csrf_token) {
      params.authenticity_token = csrf_token.content;
    }

    params['✓'] = 'utf8';

    return params;
  }

  static currencySymbol() {
    return 'R$';
  }

  static dateFormat() {
    return 'DD/MM/YY';
  }

  static fillAndAnimate(el, json, attributes) {
    for (let i = 0; i < attributes.length; i++) {
      const value = json[attributes[i].replace('-', '_')];
      const place = el.find('[data-' + attributes[i] + ']').text(value);

      this.boldAnimate(place);
    }
  }

  static goTo(url, query) {
    redirectTo(url, query);
  }

  static params(param) {
    const params = location.search.split(param + '=');

    if (params.length <= 1) {
      return '';
    }

    return params[1].split('&')[0];
  }

  static pushState(value) {
    const url = window.location.pathname + '?' + value;

    window.history.pushState(url, document.title, url);
  }

  static scrollTo(el) {
    const self = $(el);

    if (!self.length) {
      return;
    }

    $('html, body').animate(
      {
        scrollTop: self.offset().top - 100,
      },
      500,
    );
  }
}
